// https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh

import { z } from "zod";

/** `paidIn` column in the `payment` table (`payment.paidIn`) */
export const paidInValues = [
  "ACH",
  "AS Conversion",
  "Auto Pay",
  "BlytzPay",
  "Cash",
  "Check",
  "Conversion",
  "Credit Card",
  "Customer Credit",
  "CustPay",
  "Dealer",
  "Draft",
  "Expense",
  "Gift Card",
  "In Mail",
  "In person",
  "In-Person",
  "Kiosk",
  "M Gram",
  "Mail",
  "Mail/Drop Box",
  "Money Order",
  "MoneyGram",
  "MSP",
  "Night drop",
  "No Payment",
  "On Phone",
  "Online",
  "OpenEdge",
  "Other",
  "PH",
  "Phone",
  "Recurring",
  "Sidenote",
  "SIVR",
  "Speed Pay",
  "Suspense",
  "System",
  "Wire Transfer",
] as const;
export type PaidIn = (typeof paidInValues)[number];

// Outlier items/typos for `paidBy`: [AC, AD, American Expres, BC, C, C-List (Conv), CASHIER CHECK, CC, CL, CR, DB , DP, EF, FE, FG, K, Legal(3), Legal(A), Legal(R), LF, MO, NL, paidBy, PD, PMCC, PO, PPAH, PPCC, PVAH, PVCC, PVCK, RA, RE, RP, VP, WO,]
/** `paidBy` column in the `payment` table (`payment.paidBy`)*/
const paidByValues = [
  "ACH",
  "Adjustment",
  "AMERICAN EXPRESS",
  "ATM",
  "Audit",
  "Bump Payment",
  "Cash",
  "Cashier's Check",
  "Cashiers Check",
  "Charge",
  "Charge Off",
  "Check",
  "Credit Card",
  "Credit Pay-Off",
  "Debit",
  "DEBIT CARD",
  "DINERS CLUB",
  "DISCOVER",
  "Draft",
  "FEX EPS",
  "FMV",
  "INTER COMPANY TRANSF",
  "Internal",
  "IVRA",
  "IVRC",
  "Manual CC",
  "MASTER CARD",
  "Money Order",
  "MONEYGRAM",
  "No Payment",
  "Non Cash",
  "None",
  "NSF Check",
  "OpenEdge",
  "OPTIMA CARD",
  "Other",
  "Payment",
  "ReActive",
  "REFI",
  "ReImbursement",
  "Release Possession",
  "REPAY",
  "REPOSSESSION PROCEED",
  "RESTOCK REPO",
  "REVERSE PAYMENT",
  "Rewrite",
  "Take Possession",
  "Trade",
  "VISA",
  "Walk In",
  "WESTERN UNION",
];
export type PaidBy = (typeof paidByValues)[number];

/** Often used in the `paidBy` field */
export const ZPaymentType = z.enum([
  "Credit Card",
  "ACH",
  "Cash",
  "Check",
  "Manual CC",
  "Money Order",
  "Cashier's Check",
  "Other",
]);
export type ZPaymentType = z.infer<typeof ZPaymentType>;
/** @deprecated replace with `ZPaymentType` */
export enum PaymentType {
  CreditCard = "Credit Card",
  Ach = "ACH",
  Cash = "Cash",
  Check = "Check",
  ManualCC = "Manual CC",
  MoneyOrder = "Money Order",
  CashiersCheck = "Cashier's Check",
  Other = "Other",
}

/** Same as `ZCardProcessorName`, but with different casing for 'Repay' - @todo check db tables if we can consolidate */
export const PaymentProcessor = z.enum(["OpenEdge", "BlytzPay", "Repay", "Cliq"]);
export type PaymentProcessor = z.infer<typeof PaymentProcessor>;
/** Same as `PaymentProcessor`, but with different casing for 'Repay' - @todo check db tables if we can consolidate */
export const ZCardProcessorName = z.enum(["REPAY", ...PaymentProcessor.exclude(["Repay"]).options]); //.// z.enum(["OpenEdge", "BlytzPay", "REPAY", "Cliq"]);
export type ZCardProcessorName = z.infer<typeof ZCardProcessorName>;
export const cardProcessorIntLookup: { [key in ZCardProcessorName]: number } = {
  OpenEdge: 1,
  BlytzPay: 2,
  REPAY: 3,
  Cliq: 4,
};
const entries = Object.entries(cardProcessorIntLookup) as [ZCardProcessorName, number][];
const reversible: [ZCardProcessorName | number, ZCardProcessorName | number][] = [
  ...entries,
  ...(entries.map((arr) => [arr[1], arr[0]]) as [number, ZCardProcessorName][]),
];
/** Use either the int or string to retrieve the lookup object */
export const cardProcessorMap = new Map(reversible);

/** @deprecated replace with `ZCardProcessorName` */
export enum CardProcessorName {
  OpenEdge = "OpenEdge",
  BlytzPay = "BlytzPay",
  Repay = "REPAY",
}
/** @deprecated replace with `ZCardProcessorName` */
export enum CardProcessor {
  OpenEdge = 1,
  BlytzPay = 2,
  Repay = 3,
}

export const AchAccountType = z.enum(["New Account", "Account on File"]);
export type AchAccountType = z.infer<typeof AchAccountType>;

export const CcAccountType = z.enum(["New Card", "Card on File"]);
export type CcAccountType = z.infer<typeof CcAccountType>;

/** @note Empty string is an expected response value */
export const ZRepayFeeModel = z.enum(["NOFEE", "FEE_CAN_WAIVE", "FEE_NO_WAIVE", ""]);
export type ZRepayFeeModel = z.infer<typeof ZRepayFeeModel>;

export const PaymentInterval = z.enum(["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"]);
export type PaymentInterval = z.infer<typeof PaymentInterval>;

export const TransactionType = z.enum(["Payment", "Payoff"]);
export type TransactionType = z.infer<typeof TransactionType>;

export const PaymentAcceptedIn = z.enum(["In-Person", "Phone", "Mail", "Night drop"]);
export type PaymentAcceptedIn = z.infer<typeof PaymentAcceptedIn>;

export const ReversalType = z.enum(["chargeBack", "refund", "reapply", "nsfCheck", "reversal"]);
export type ReversalType = z.infer<typeof ReversalType>;
// @todo rename
const reversalTypeLabelArrMap: [ReversalType, string][] = [
  ["chargeBack", "Credit Card Chargeback"],
  ["refund", "Refund"],
  ["reapply", "Reapply"],
  ["nsfCheck", "NSF Check"],
  ["reversal", "Payment Reversal"],
];
export const reversalLabelMap = new Map<ReversalType, string>(reversalTypeLabelArrMap);
export const reversalLookupMap = new Map<string, ReversalType>(
  reversalTypeLabelArrMap.map((item) => [item[1], item[0]])
);
export const reversalTypeLabels: string[] = [];
reversalLabelMap.forEach((v, _) => {
  reversalTypeLabels.push(v);
});
export const reversalTypeDropdownLabels: { key: ReversalType; value: string }[] = [];
reversalLabelMap.forEach((value, key) => {
  reversalTypeDropdownLabels.push({ key, value });
});
