import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { NumericTextBoxBlurEvent } from '@progress/kendo-react-inputs';
import { Icons } from '@/components/icons';
// components
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { usePaymentSelector } from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice';
// utils
import { collectionsService } from '@/services/collectionsService';
import { salesService } from '@/services/salesService';
import {
  Employee,
  getDefaultPostPaymentPayloadNew,
  GetPaymentData,
  PaymentProviders,
  paymentService,
} from '@/services/paymentService';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getConvenienceFee,
  getIsNewPayment,
  getMpdToken,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  getSavePayment,
  pollForReceipt,
} from '@/utils/helpers/payment';
import { formatCurrency, getInitials } from '@/utils/helpers/general';
import { lowerCaseLetters } from '@/utils/helpers/formatting';
// interfaces
import { Column } from '@/components/table/TableInterface';
import { CardProcessorName, PaymentType } from '@/enums';
import { DownPayment, SavedPaymentMethod } from '@/interfaces';
// style
import styles from '../SaleManagement.module.scss';

/** @deprecated convert to context */
export const useDownPayment = () => {
  const params = useParams();
  const appRecId = Number(params.id);
  const realSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const { compId, userName } = useAuthSelector((s) => s);

  const { postPaymentLoading } = usePaymentSelector((s) => s);

  const [showDownPaymentForm, setShowDownPaymentForm] = useState(false);
  const [downPaymentList, setDownPaymentList] = useState<DownPayment[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<Employee[]>([]);
  const [providerData, setProviderData] = useState<PaymentProviders>();
  const [repayIframeUrl, setRepayIframeUrl] = useState('');
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [savedCards, setSavedCards] = useState<SavedPaymentMethod[]>([]);
  const [savedAccounts, setSavedAccounts] = useState<SavedPaymentMethod[]>([]);
  const [paymentDetails, setPaymentDetails] = useState<GetPaymentData | undefined>(undefined);
  const [processors, setProcessors] = useState<CardProcessorName[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const acceptedInData = ['In-Person', 'Phone', 'Mail', 'Night Drop'];
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const formDefaultValues = {
    processor: CardProcessorName.Repay,
    acceptedIn: 'In-Person',
    downPayment: 0,
    totalPaid: 0,
    paymentType: 'Cash',
    referenceNumber: '',
    amountTendered: 0,
    changeDue: 0,
    password: '',
    employee: undefined as unknown as Employee,
    paymentNote: '',
    convenienceFee: 0,
    waiveFee: false,
    transactions: 0,
    accountType: 'Checking',
    saveCard: false,
    firstName: '',
    lastName: '',
    zip: '',
    address: '',
    city: '',
    state: '',
    paymentOnFile: undefined as unknown as SavedPaymentMethod,
    accountNumber: '',
    routingNumber: '',
    sendBuyerEmail: false,
    sendCoBuyerEmail: false,
  };
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
    setError,
  } = useForm({ defaultValues: formDefaultValues });
  const formValues = useWatch({ control });

  // @todo use existing component
  const DateCell: FC = (props: any) => {
    const value = props.dataItem[props.field];
    return (
      <td style={{ color: '#737373' }}>{value ? dayjs(value).utc().format('MM/DD/YYYY') : ''}</td>
    );
  };

  // @todo use existing component
  const CurrencyCell: FC = (props: any) => {
    const value = props.dataItem[props.field];
    return <td style={{ color: '#737373' }}>{value ? formatCurrency(value) : ''}</td>;
  };

  // @todo use existing component
  const PrintCell: FC = (props: any) => {
    const pmtRecId: number = props.dataItem.recId;
    return (
      <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div
          onClick={() => {
            // @todo use async/await
            paymentService.getReceiptUrl(pmtRecId).then((res) => window.open(res));
          }}
        >
          <Icons.Print className={styles.printCell} />
        </div>
      </td>
    );
  };

  // @todo move object/array/function out of component body
  const downPaymentColumns: Column[] = [
    {
      field: 'pmtDate',
      title: 'Payment Date',
      cells: { data: DateCell },
    },
    {
      field: 'payToFrom',
      title: 'Paid By',
    },
    {
      field: 'totalApplied',
      title: 'Amt. Paid',
      cells: { data: CurrencyCell },
    },
    {
      field: 'paidBy',
      title: 'Pay Method',
    },
    {
      field: 'takenBy',
      title: 'Taken By',
    },
    {
      field: 'reprintReceipt',
      title: ' ',
      cells: { data: PrintCell },
    },
  ];

  const onCollectClick = () => {
    setShowDownPaymentForm(true);
  };

  const handleAmountTenderedBlur = (e: NumericTextBoxBlurEvent) => {
    if (e.target.value! < (formValues.totalPaid || 0)) {
      setValue('amountTendered', formValues.totalPaid || 0);
    }
  };

  const initData = async () => {
    setDataLoading(true);

    try {
      const colRecId = await collectionsService.getColRecId(appRecId, 'CD');
      const users = await paymentService.getUsersByCompanyId(compId!);
      setEmployeeOptions(users);
      const defaultEmployee = users.find((user) => user.shortName === userName);
      if (defaultEmployee) {
        setValue('employee', defaultEmployee);
      } else {
        setValue('employee', users[0] ? users[0] : (undefined as unknown as Employee));
      }

      const downPaymentList = await salesService.getDownPaymentList(appRecId);
      setDownPaymentList(downPaymentList);

      // @todo use async/await
      await paymentService.getPaymentProviders(colRecId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        const allowedPaymentTypes = getAllowedPaymentTypes(paymentProviders);
        setPaymentTypes(allowedPaymentTypes);
        setProcessors(enabledProviders);
        setValue('paymentType', allowedPaymentTypes[0] || '');
        setValue('processor', preferredProviderName);
      });

      if (appRecId) {
        // @todo use async/await
        await paymentService.getSavedPaymentMethods(appRecId!).then((res) => {
          const savedCards = res.filter((card) => {
            return (
              card.mpdType === 'CreditCard' &&
              lowerCaseLetters(card.cardProcessor) === lowerCaseLetters(formValues.processor) &&
              card.fName &&
              card.lName &&
              card.last4 &&
              card.mpdId &&
              card.isActive
            );
          });

          const savedAch = res.filter((pm) => {
            return (
              pm.isActive &&
              pm.mpdType === 'Ach' &&
              pm.fName &&
              pm.lName &&
              pm.mpdId &&
              pm.last4 &&
              lowerCaseLetters(pm.cardProcessor) === lowerCaseLetters(formValues.processor)
            );
          });

          setSavedCards(savedCards);
          setSavedAccounts(savedAch);
        });
      }

      const paymentDetails = await paymentService.getPaymentDetails(colRecId);
      setPaymentDetails(paymentDetails);
      setValue('firstName', paymentDetails.buyerFirstName);
      setValue('lastName', paymentDetails.buyerLastName);
      setValue('address', paymentDetails.buyerAddr);
      setValue('city', paymentDetails.buyerCity);
      setValue('zip', paymentDetails.buyerZip);
      setValue('state', paymentDetails.buyerState);
      setValue('downPayment', paymentDetails.tOfPBal || 0);
      setValue('totalPaid', paymentDetails.tOfPBal || 0);
      setValue('amountTendered', paymentDetails.tOfPBal || 0);
    } catch (error) {
      toast.error('Unable to receive payment data');
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  const submitDownPayment = async (data: typeof formDefaultValues) => {
    try {
      const tempDmsPayload = getDefaultPostPaymentPayloadNew(paymentDetails!);

      const paymentToSend = data.amountTendered;

      let mpdToken = '';
      if (data.paymentOnFile) {
        mpdToken = getMpdToken(
          data.paymentType!,
          data.paymentOnFile!.mpdId!,
          data.paymentOnFile!.mpdId!
        );
      }

      const savePayment = mpdToken
        ? false
        : getSavePayment(data.paymentType!, data.saveCard!, data.saveCard!);
      const isNewPayment = getIsNewPayment(
        data.paymentType!,
        data.transactions === 0,
        data.transactions === 0
      );
      const takenBy = getInitials(data.employee!.shortName!);
      const processorType = getProcessorIntByName(data.processor!);
      const achAcctType = getAchAcctType(data.paymentType!, data.accountType!);
      const isAch = data.paymentType === PaymentType.Ach;
      const isCC = data.paymentType === PaymentType.CreditCard;
      const ccConvFee = isCC ? data.convenienceFee : 0;
      const achConvFee = isAch ? data.convenienceFee : 0;
      const waiveCCConvFee = isCC && data.waiveFee;
      const waiveAchConvFee = isAch && data.waiveFee;

      tempDmsPayload!.AchAcctType = achAcctType;
      tempDmsPayload!.AchConvFee = achConvFee;
      tempDmsPayload!.BillAddress = data.address!;
      tempDmsPayload!.BillCity = data.city!;
      tempDmsPayload!.BillEmail = paymentDetails!.buyerEmail;
      tempDmsPayload!.BillFirstName = data.firstName;
      tempDmsPayload!.BillLastName = data.lastName;
      tempDmsPayload!.BillState = data.state;
      tempDmsPayload!.BillZip = data.zip;
      tempDmsPayload!.CarPmt = data.totalPaid;
      tempDmsPayload!.CcConvFee = ccConvFee;
      tempDmsPayload!.Change = data.changeDue ?? 0;
      tempDmsPayload!.ColRecId = paymentDetails!.colRecId;
      tempDmsPayload!.ColType = paymentDetails!.colType;
      tempDmsPayload!.ConvFee = data.convenienceFee;
      tempDmsPayload!.EmailB = paymentDetails!.buyerEmail;
      tempDmsPayload!.EmailC = paymentDetails!.cobuyerEmail;
      tempDmsPayload!.IsAch = isAch;
      tempDmsPayload!.IsNewCard = isNewPayment;
      tempDmsPayload!.Mpd.AccountNumber = data.accountNumber;
      tempDmsPayload!.Mpd.RoutingNumber = data.routingNumber;
      tempDmsPayload!.Mpd.Token = mpdToken;
      tempDmsPayload!.PaidBy = data.paymentType;
      tempDmsPayload!.PaidIn = data.acceptedIn;
      tempDmsPayload!.PaidRef = data.referenceNumber;
      tempDmsPayload!.PayNote = data.paymentNote;
      tempDmsPayload!.PmtContext = 'SALE_CASHDOWN';
      tempDmsPayload!.PmtType = data.paymentType;
      tempDmsPayload!.ProcessorType = processorType;
      tempDmsPayload!.SaveCard = savePayment;
      tempDmsPayload!.SendB = data.sendBuyerEmail;
      tempDmsPayload!.SendC = data.sendCoBuyerEmail;
      tempDmsPayload!.TakenBy = takenBy;
      tempDmsPayload!.TakenByPassword = data.password;
      tempDmsPayload!.TotalReceived = paymentToSend;
      tempDmsPayload!.UserEmail = data.employee!.userId;
      tempDmsPayload!.UserRecId = data.employee!.recId;
      tempDmsPayload!.UserShortName = data.employee?.shortName || '';
      tempDmsPayload!.WaiveAchConvFee = waiveAchConvFee;
      tempDmsPayload!.WaiveCCConvFee = waiveCCConvFee;
      tempDmsPayload!.WaiveConvFee = data.waiveFee;

      if (
        (data.paymentType !== PaymentType.CreditCard && data.paymentType !== PaymentType.Ach) ||
        tempDmsPayload?.Mpd.Token ||
        (data.paymentType === PaymentType.Ach && data.processor === CardProcessorName.OpenEdge)
      ) {
        // @todo use async/await
        await paymentService.postPaymentSubmit(tempDmsPayload!).then((res) => {
          pollForReceipt(res.paymentLogRecId);
          initData();
          setShowDownPaymentForm(false);
        });
      } else if (data.processor === CardProcessorName.Repay && tempDmsPayload) {
        // Get iframe url
        // @todo use async/await
        await paymentService.postPaymentSubmit(tempDmsPayload).then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setRepayIframeUrl(res.iFrameUrl);
        });
      } else if (
        data.processor === CardProcessorName.OpenEdge &&
        data.paymentType === PaymentType.CreditCard
      ) {
        // @todo use async/await
        await paymentService.postPaymentSubmit(tempDmsPayload!).then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setOpenEdgeModal(true);
        });
      } else {
        // @todo use async/await
        await paymentService.postPaymentSubmit(tempDmsPayload!).then((res) => {
          pollForReceipt(res.paymentLogRecId);
          initData();
          setShowDownPaymentForm(false);
        });
      }
    } catch (e: any) {
      console.error(e.response.status, e.response.data);
      if (
        e?.response?.status === 403 &&
        e.response.data === 'Password for selected user is incorrect.'
      ) {
        setError(
          'password',
          {
            type: 'validate',
            message: 'Password for selected user is incorrect',
          },
          { shouldFocus: true }
        );
      }
    }
  };

  useEffect(() => {
    if (!paymentDetails || !formValues.processor) return;
    setValue(
      'convenienceFee',
      getConvenienceFee(
        paymentDetails!,
        formValues.processor,
        formValues.paymentType!,
        formValues.waiveFee!
      )
    );
  }, [formValues.paymentType, formValues.processor, formValues.waiveFee]);

  useEffect(() => {
    const amountDelta = watch('amountTendered') - watch('totalPaid');
    if (
      amountDelta < 0 ||
      formValues.paymentType === PaymentType.CreditCard ||
      formValues.paymentType === PaymentType.Ach
    ) {
      setValue('changeDue', undefined as unknown as number);
    } else {
      setValue('changeDue', amountDelta);
    }
  }, [formValues.totalPaid, formValues.amountTendered]);

  useEffect(() => {
    if (
      formValues.paymentType !== PaymentType.CreditCard &&
      formValues.paymentType !== PaymentType.Ach
    ) {
      setValue('amountTendered', formValues.totalPaid || 0);
    } else if (!formValues.waiveFee) {
      setValue('amountTendered', (formValues.totalPaid || 0) + (formValues.convenienceFee || 0));
    } else {
      setValue('amountTendered', formValues.totalPaid || 0);
    }
  }, [formValues.convenienceFee, formValues.waiveFee, formValues.processor, formValues.totalPaid]);

  useEffect(() => {
    initData();
  }, [appRecId]);

  return {
    downPaymentColumns,
    showDownPaymentForm,
    downPaymentList,
    onCollectClick,
    setShowDownPaymentForm,
    control,
    handleSubmit,
    processors,
    acceptedInData,
    paymentTypes,
    watch,
    dataLoading,
    employeeOptions,
    handleAmountTenderedBlur,
    savedCards,
    savedAccounts,
    openEdgeModal,
    repayIframeUrl,
    paymentLogRecId,
    providerData,
    paymentDetails,
    setRepayIframeUrl,
    setOpenEdgeModal,
    errors,
    realSubmitButtonRef,
    postPaymentLoading,
    submitDownPayment,
    initData,
    isDirty,
  };
};
