import { FC, useEffect, useState } from 'react';
import { Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash-es';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
// kendo
import { ComboBoxChangeEvent, NumericTextBoxChangeEvent } from '@progress/kendo-react-all';
import { RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import { Button, Checkbox, DropdownInput, RadioGroupInput, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { ConfirmButton } from '@/components/confirmButton/ConfirmButton';
// components
import FormBackButton from './FormBackButton';
import LateChargeField from './LeftColumnFieldGroup/LateChargeField';
import FeeFieldGroup from './LeftColumnFieldGroup/FeeFieldGroup';
import NsfPaidFieldGroup from './LeftColumnFieldGroup/NsfPaidFieldGroup';
import PaymentInfoFieldGroup from './RightColumnFieldGroup/PaymentInfoFieldGroup';
import MpdFieldGroup from './RightColumnFieldGroup/MpdFieldGroup';
import BillingInfoFieldGroup from './RightColumnFieldGroup/BillingInfoFieldGroup';
import PendingRewriteModal from './PendingRewriteModal';
import RepayModal from '@/components/modals/PaymentModalGroup/RepayModal';
import OpenEdgeModal from '@/components/modals/PaymentModalGroup/OpenEdgeModal';
// state
import { useWholesaleFormCtx } from '../../WholesaleFormProvider';
import { usePaymentSelector } from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice';
import { useWsPmtsFormCtx } from './WsPmtsFormProvider';
import { useWsPmtsViewCtx } from '../WsPmtsViewProvider';
import useCarPaymentListener from './hooks/useCarPaymentListener';
import useChangeFieldListener from './hooks/useChangeFieldListener';
import usePaymentProviderListener from './hooks/usePaymentProviderListener';
import useTotalPaymentListener from './hooks/useTotalPaymentListener';
import useTotalReceivedListener from './hooks/useTotalReceivedListener';
import usePaymentInfoListener from './hooks/usePaymentInfoListener';
// utils
import { useNavUp } from '@/utils/routing/useNavUpEvent';
import { useNavigationConfirm } from '@/hooks';
import { accountsService } from '@/services/accountsService';
import { getDdDueStatus, getProcessorIntByName, pollForReceipt } from '@/utils/helpers/payment';
import { getInitials } from '@/utils/helpers/general';
import { accountTypeOptions, paidInOptions, transactionTypeOptions } from '../default';
import { config } from '@/config';
// interfaces
import { CardProcessorName } from '@/enums';
import {
  Employee,
  GetPaymentData,
  PostPaymentPayload,
  paymentService,
} from '@/services/paymentService';

const handleChangeCpiPaid =
  (setValue: UseFormSetValue<PostPaymentPayload>) => (e: NumericTextBoxChangeEvent) =>
    setValue('CpiPaid', e.value || 0);

/** ### @todo Load default form data in parent component */
const WsCollectForm: FC = () => {
  const [isFormUnsubmitted, setIsFormUnsubmitted] = useState(true);
  const isPostPaymentLoading = usePaymentSelector((s) => s.postPaymentLoading);
  const setShowPendingRewriteModal = useWsPmtsFormCtx((s) => s.setShowPendingRewriteModal);
  const setPaymentLogRecId = useWsPmtsFormCtx((s) => s.setPaymentLogRecId);
  const setRepayIframeUrl = useWsPmtsFormCtx((s) => s.setRepayIframeUrl);
  const paymentProviderData = useWsPmtsViewCtx((s) => s.paymentProviderData);
  const openEdgeCCModalOpen = useWsPmtsFormCtx((s) => s.openEdgeCCModalOpen);
  const setOpenEdgeCCModalOpen = useWsPmtsFormCtx((s) => s.setOpenEdgeCCModalOpen);
  const paymentLogRecId = useWsPmtsFormCtx((s) => s.paymentLogRecId);
  const repayIframeUrl = useWsPmtsFormCtx((s) => s.repayIframeUrl);

  // Form state
  const { control, handleSubmit, setValue, formState } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { errors, isDirty, isValid } = formState;

  // Form option arrays (dropdowns, radio button groups, etc)
  const salespeople = useWholesaleFormCtx((s) => s.formAddlData?.salespeople || []);
  const enabledProviders = useWsPmtsViewCtx((s) => s.enabledProviders);
  const paymentTypes = useWsPmtsFormCtx((s) => s.paymentTypes);

  // Form field state values
  const provider = useWsPmtsViewCtx((s) => s.provider);
  const setProvider = useWsPmtsViewCtx((s) => s.setProvider);
  const transactionType = useWsPmtsFormCtx((s) => s.transactionType);
  const setTransactionType = useWsPmtsFormCtx((s) => s.setTransactionType);
  const totalPayment = useWsPmtsFormCtx((s) => s.totalPayment);
  const setTotalPayment = useWsPmtsFormCtx((s) => s.setTotalPayment);
  const employee = useWsPmtsFormCtx((s) => s.employee);
  const setEmployeeName = useWsPmtsFormCtx((s) => s.setEmployeeName);

  // booleans and getters
  const cpiDueDateFmt = useWsPmtsFormCtx((s) => s.cpiDueDateFmt);
  const isPrincipalOnly = useWsPmtsFormCtx((s) => s.isPrincipalOnly);
  const shouldUseSavedPaymentMethod = useWsPmtsFormCtx((s) => s.shouldUseSavedPaymentMethod);
  const isRegularPayment = useWsPmtsFormCtx((s) => s.isRegularPayment);
  const isCc = useWsPmtsFormCtx((s) => s.isCc);
  const isAch = useWsPmtsFormCtx((s) => s.isAch);

  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const maxPayment = paymentData?.maxPayment || 0;
  const isTotalPmtNegative = !totalPayment || totalPayment < 0;
  const isTotalPmtGteMaxPmt = totalPayment > (maxPayment || 0);

  const { Mpd, PaidIn, CarPmt, AchAcctType, TotalReceived: _ } = useWatch({ control });

  // Event handlers
  const loadPmtViewState = useWsPmtsViewCtx((s) => s.loadPmtViewState);
  const resetFormState = useWsPmtsFormCtx((s) => s.resetFormState);
  const navUp = useNavUp();
  const onSuccess = async () => {
    setIsFormUnsubmitted(false);
    await loadPmtViewState();
    resetFormState();
    navUp();
  };

  const handleFormSubmit = async (paymentPayload: PostPaymentPayload) => {
    // Early exit: form errors
    const isTotalPmtError = isTotalPmtNegative || isTotalPmtGteMaxPmt;
    if (isPostPaymentLoading || (isRegularPayment && isTotalPmtError)) return;
    if (!paymentData) return toast.error('Payment-data not set');

    // Early exit: pending rewrite
    const isPendingRewrite = await accountsService.getIsPendingRewrite(paymentData.appRecId);
    if (isPendingRewrite) return setShowPendingRewriteModal(true);

    // From @qualitymanifest:
    // This is good enough for now, an inline error would be better
    // An inline error is just clunky to implement because the saved payment state is not contained in the form
    if (shouldUseSavedPaymentMethod && !Mpd?.Token)
      return toast.error('Select a saved payment method or enter a new payment method');

    const takenBy = getInitials(employee!.shortName);
    const processorType = getProcessorIntByName(provider!);

    const finalPayload = cloneDeep(paymentPayload);
    finalPayload.TakenBy = takenBy;
    finalPayload.ProcessorType = processorType;
    finalPayload.PmtType = finalPayload.PaidBy;

    // @note This logic is copied from `Payment.tsx` (accounts) - may be incorrect. Submit should happen after CC receipt is confirmed.
    let paymentSubmitRes: any;
    try {
      paymentSubmitRes = await paymentService.postPaymentSubmit(finalPayload);
      // Error is handled in request method
    } catch {}

    if (
      (!isCc && !isAch) ||
      finalPayload!.Mpd.Token ||
      (isAch && provider === CardProcessorName.OpenEdge)
    ) {
      try {
        await pollForReceipt(paymentSubmitRes.paymentLogRecId, onSuccess);
        // Error is handled in request function
      } catch {}
    } else if (provider === CardProcessorName.Repay) {
      setPaymentLogRecId(paymentSubmitRes.paymentLogRecId);
      setRepayIframeUrl(paymentSubmitRes.iFrameUrl);
    } else if (provider === CardProcessorName.OpenEdge && isCc) {
      setPaymentLogRecId(paymentSubmitRes.paymentLogRecId);
      setOpenEdgeCCModalOpen(true);
    }
  };
  /** ### Update payment-related states when transaction type changes */
  const handleChangeTransactionType = (e: RadioGroupChangeEvent) => {
    if (paymentData === null) return;

    if (isPrincipalOnly) {
      setValue('LcOwed', undefined);
      setValue('PaymentType', 'PrinOnly');
      setValue('PmtContext', 'NEW_UI_MISC');
      return;
    }

    const isPayoff = transactionType === 'Payoff';
    const totalPmt = isPayoff ? paymentData.maxPayment : paymentData.dmsNextDueAmount;

    setValue('CarPmt', isPayoff ? paymentData.payOff : paymentData.pmtDue);
    setValue('CpiPaid', paymentData.cpiDueNow);
    setValue('LcPaid', paymentData.lcDue);
    setValue('DdPmt', paymentData.ddDueNow);
    setValue('NsfPaid', paymentData.nsfDue);
    setValue('TotalReceived', totalPmt);
    setTotalPayment(totalPmt);
    setTransactionType(e.value);
  };

  // Only update the "total payment" state - derived from response data - when payment data updates (happens when colRecId changes)
  const totalPaymentCalc = useWsPmtsFormCtx((s) => s.totalPaymentCalc);
  useEffect(() => {
    // When payment data changes, set "total payment" to derived value
    if (paymentData?.colRecId) setTotalPayment(totalPaymentCalc);
  }, [paymentData?.colRecId]);
  useCarPaymentListener();
  useChangeFieldListener();
  usePaymentInfoListener();
  usePaymentProviderListener();
  useTotalPaymentListener();
  useTotalReceivedListener();

  const { NavigationConfirm } = useNavigationConfirm(isFormUnsubmitted);

  const isTotalPaymentInvalid = isRegularPayment && (isTotalPmtNegative || isTotalPmtGteMaxPmt);
  const areNonFormFieldsInvalid = !employee || !!isTotalPaymentInvalid || !provider;
  const isSubmitButtonDisabled =
    !isDirty || !isValid || areNonFormFieldsInvalid || isPostPaymentLoading;

  return (
    <Grid container direction="column">
      <FormBackButton />
      <Grid
        container
        direction="row"
        columnGap={3}
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Grid container direction="column" gap={1}>
          {enabledProviders.length >= 1 && (
            <DropdownInput
              label="Processor"
              data={enabledProviders}
              value={provider}
              disabled={enabledProviders.length === 1}
              onChange={(e) => setProvider(e.target.value)}
              errors={!provider}
            />
          )}

          {isRegularPayment && (
            <RadioGroupInput
              label="Transaction Type"
              data={transactionTypeOptions}
              layout="horizontal"
              style={{ height: '38px' }}
              value={transactionType}
              onChange={handleChangeTransactionType}
            />
          )}

          <Controller
            name="PaidIn"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <DropdownInput
                  label="Paid In"
                  data={paidInOptions}
                  required
                  errors={!PaidIn}
                  {...field}
                />
              );
            }}
          />

          {/* @note not part of the submission */}
          {isRegularPayment && (
            <CurrencyInput
              label="Total Payment"
              required
              value={totalPayment}
              onChange={(e) => setTotalPayment(e.target.value || 0)}
              errors={
                isTotalPmtNegative
                  ? 'Total Payment must be a positive number'
                  : isTotalPmtGteMaxPmt
                  ? `Total Payment cannot exceed maximum payment of ${maxPayment}`
                  : false
              }
            />
          )}

          {/* @note not part of the submission */}
          {isPrincipalOnly && (
            <CurrencyInput label="Principal Balance" readOnly value={paymentData?.prinBal ?? 0} />
          )}

          <Controller
            name="CarPmt"
            control={control}
            rules={{
              min: isPrincipalOnly
                ? { value: 0.01, message: 'Payment must be greater than 0' }
                : { value: 0, message: 'Payment cannot be negative' },
              max: isPrincipalOnly
                ? { value: paymentData?.prinBal ?? 0, message: 'Cannot exceed principal balance' }
                : undefined,
            }}
            render={({ field }) => (
              <CurrencyInput
                label={isPrincipalOnly ? 'Principal Payment' : 'Car Payment'}
                readOnly={!isPrincipalOnly}
                errors={errors.CarPmt?.message}
                {...field}
              />
            )}
          />

          {isRegularPayment && paymentData?.onCpi && (
            <Controller
              name="CpiPaid"
              control={control}
              rules={{ required: 'CPI Due is required' }}
              render={({ field: { onChange, ...field } }) => (
                <CurrencyInput
                  label={`CPI Due${cpiDueDateFmt ? ` on ${cpiDueDateFmt}` : ''}`}
                  required
                  onChange={handleChangeCpiPaid(setValue)}
                  errors={errors.CpiPaid?.message}
                  {...field}
                />
              )}
            />
          )}

          {isRegularPayment && !!paymentData?.lcDue && <LateChargeField />}

          {/* showNsfCharge */}
          {isRegularPayment && !!paymentData?.nsfDue && <NsfPaidFieldGroup />}

          {/* showDDCharge */}
          {isRegularPayment && !!paymentData?.defDownBal && (
            <>
              <Controller
                name="DdPmt"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CurrencyInput label={`DD Due`} required errors={!!errors.DdPmt} {...field} />
                )}
              />
              <small style={{ fontSize: '12px', fontStyle: 'italic', marginLeft: 'auto' }}>
                {paymentData ? getDdDueStatus(paymentData as GetPaymentData) : ''}
              </small>
            </>
          )}

          <Controller
            name="PaidBy"
            control={control}
            rules={{ required: 'Payment type is required' }}
            render={({ field }) => (
              <DropdownInput
                label="Payment Type"
                required
                data={paymentTypes}
                errors={!!errors.PaidBy}
                {...field}
              />
            )}
          />

          {(isCc || isAch) && <FeeFieldGroup />}

          <Controller
            name="TotalReceived"
            control={control}
            rules={{
              required: !isCc && !isAch,
              min: isPrincipalOnly
                ? {
                    value: CarPmt!,
                    message: 'Amount tendered cannot be less than the principal payment',
                  }
                : {
                    value: totalPayment,
                    message: 'Amount tendered cannot be less than the total payment',
                  },
            }}
            render={({ field }) => (
              <CurrencyInput
                label="Amount Tendered"
                required={!isCc && !isAch}
                readOnly={isCc || isAch}
                errors={errors.TotalReceived?.message || !!errors.TotalReceived}
                {...field}
              />
            )}
          />

          <Controller
            name="Change"
            control={control}
            render={({ field }) => <CurrencyInput label="Change Due" readOnly {...field} />}
          />

          <Spacer expand />

          {paymentData?.buyerEmail && !paymentData.buyerNoEmail && (
            <Controller
              name="SendB"
              control={control}
              render={({ field }) => <Checkbox label="Send Buyer Email Receipt" {...field} />}
            />
          )}

          {paymentData?.cobuyerEmail && !paymentData.cobuyerNoEmail && (
            <Controller
              name="SendC"
              control={control}
              render={({ field }) => <Checkbox label="Send Cobuyer Email Receipt" {...field} />}
            />
          )}
        </Grid>

        <Divider orientation="vertical" />

        {/* Employee section */}
        <Grid container direction="column" gap={1} justifyContent="end">
          {(isCc || isAch) && <PaymentInfoFieldGroup />}

          {provider === CardProcessorName.OpenEdge && isAch && !shouldUseSavedPaymentMethod && (
            <MpdFieldGroup />
          )}

          {isCc && !shouldUseSavedPaymentMethod && <BillingInfoFieldGroup />}

          {isAch && !shouldUseSavedPaymentMethod && (
            <Controller
              name="AchAcctType"
              control={control}
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <RadioGroupInput
                    label="Account Type"
                    data={accountTypeOptions}
                    layout="horizontal"
                    value={AchAcctType}
                    onChange={(e) => setValue('AchAcctType', e.value)}
                    {...field}
                  />
                );
              }}
            />
          )}

          <Spacer expand />

          <DropdownInput
            label="Employee"
            required
            data={salespeople}
            dataItemKey="recId"
            textField="shortName"
            onChange={(e: Omit<ComboBoxChangeEvent, 'value'> & { value: Employee | null }) => {
              setValue('TakenBy', e.value?.shortName || '');
              setValue('UserEmail', e.value?.userId || '');
              setValue('UserShortName', e.value?.shortName || '');
              // @todo update form interface to handle null
              setValue('UserRecId', e.value?.recId || 0);

              setEmployeeName(e.value?.shortName || null);
            }}
            value={employee}
            errors={!employee}
          />

          <Controller
            name="TakenByPassword"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                label="Password"
                type="password"
                required
                errors={!!errors.TakenByPassword}
                {...field}
              />
            )}
          />

          <Controller
            name="PaidRef"
            control={control}
            render={({ field }) => <TextInput label="Reference #" {...field} />}
          />

          <Controller
            name="PayNote"
            control={control}
            render={({ field }) => <TextInput label="Payment Note" {...field} />}
          />

          <ConfirmButton
            triggerElement={(onClick) => (
              <Grid container alignSelf="end">
                <Button
                  label="Post Payment"
                  disabled={isSubmitButtonDisabled}
                  loading={isPostPaymentLoading}
                  style={{ width: '211px' }}
                  onClick={onClick}
                />
              </Grid>
            )}
            confirmButtonProps={{ type: 'submit' }}
            modalContents="Please confirm that you want to post this payment"
          />
        </Grid>

        <RepayModal
          iframeUrl={repayIframeUrl}
          setIframeUrl={setRepayIframeUrl}
          paymentLogRecId={paymentLogRecId}
          onComplete={onSuccess}
        />
        <OpenEdgeModal
          apiKey={paymentData?.openedgeApiKey}
          openEdgeEnv={
            paymentProviderData?.openEdgeEnv
              ? paymentProviderData.openEdgeEnv
              : config?.openEdgeEnvironment
          }
          isOpen={openEdgeCCModalOpen}
          setIsOpen={setOpenEdgeCCModalOpen}
          paymentLogRecId={paymentLogRecId}
          onComplete={onSuccess}
        />
        <PendingRewriteModal />
        {NavigationConfirm}
      </Grid>
    </Grid>
  );
};

export default WsCollectForm;
