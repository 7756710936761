import { FC, useState } from 'react';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { Loader } from '@progress/kendo-react-all';
import { DeclineNotice } from './declineNotice/DeclineNotice';
import DownPayment from './downPayment/DownPayment';
import { AppStatus } from '@/features/Sales/enums';
import { SaleManagementForm } from './saleManagementForm/SaleManagementForm';
import styles from './SaleManagement.module.scss';

export const SaleManagement: FC = () => {
  const { saleData, saleDataLoading } = useSalesSelector((s) => s);
  const [showDownPayment, setShowDownPayment] = useState(false); // this should be converted to routing

  return (
    <div className={styles.saleManagementContainer}>
      {saleDataLoading ? (
        <div className={styles.formContainer}>
          <div className={styles.loading}>
            <Loader size="large" />
          </div>
        </div>
      ) : saleData?.sale?.appstatus === AppStatus.Declined ? (
        <DeclineNotice />
      ) : showDownPayment ? (
        <DownPayment setShowDownPayment={setShowDownPayment} />
      ) : (
        <SaleManagementForm setShowDownPayment={setShowDownPayment} />
      )}
    </div>
  );
};
