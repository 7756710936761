import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { isEqual } from "lodash";
import { isNull } from "lodash-es";
import { isDayjs } from "dayjs";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
// utils
import { Nullish } from "@/interfaces/utilityTypes";
import { buildDefaultSchema } from "./utils";
// interfaces
import { SchemaFallback, OnChangeEventUnion } from "./interfaces";

/** ### Stateful form with validation, based on `zod`.
 *
 * - While the validation schema and initialization schema are different, which The values may be nullable/invalid, and this should be reflected
 * - We mainly need the `form` var for deriving the `isValid` (and occasionally value access when not using `getFieldProps`)
 *
 * @todo Add logic to handle nested fields (candidate impl.: use a 'selector' callback)
 * @todo Optionally handle request body validation
 */
const useValidatedForm = <
  TFormSchema extends z.ZodObject<TFormShape>,
  TFormShape extends z.ZodRawShape
>(
  inputFormSchema: z.ZodEffects<TFormSchema> | TFormSchema,
  defaultFormValues?: Nullish<z.input<TFormSchema>> | null
) => {
  /** @note This object is memoized because:
   *   - Updates frequently (on each field change)
   *   - Can be expensive to recalculate (affects performance as # of fields (and the amount of nesting) increases
   */
  const baseFormSchema: TFormSchema = useMemo(() => {
    // Extract the base schema from its refined form (if refined)
    const innerFormSchema: TFormSchema =
      inputFormSchema instanceof z.ZodEffects ? inputFormSchema.innerType() : inputFormSchema;

    if (innerFormSchema instanceof z.ZodEffects)
      throw new Error(
        "Refined schemas must use either a single `.refine`. Use `.superRefine` for one or more refinements."
      );

    return innerFormSchema;
  }, []);
  const schemaWithDefaults: SchemaFallback<TFormSchema> = useMemo(
    () => buildDefaultSchema(baseFormSchema),
    []
  );
  /** @note Used for checking if first render (**BEFORE**, state/passed in defaults are set ) */
  const initializedForm = useMemo(() => schemaWithDefaults.parse({}), []);

  // @note Used for initializing form-field states
  // Form-field states
  const [referenceFormValues, setReferenceFormValues] = useState(
    schemaWithDefaults.parse(defaultFormValues ?? {})
  );
  const [form, setForm] = useState(schemaWithDefaults.parse(defaultFormValues ?? {}));

  // ----------------- Utilities (below) -----------------
  const resetToDefault = (
    newDefaultValues?: Nullish<z.input<TFormSchema>> | null,
    overwriteFormValues: boolean = false
  ) => {
    const updatedDefaultForm = {
      ...(overwriteFormValues ? {} : form),
      ...(newDefaultValues ?? {}),
    };
    const parsed = schemaWithDefaults.parse(updatedDefaultForm);
    setReferenceFormValues(parsed);
    setForm(parsed);
  };

  const setField = <TField extends keyof z.input<TFormSchema>>(
    field: TField,
    value: z.input<TFormSchema>[TField] | null
  ): void => {
    setForm((prevFormValues) => ({ ...prevFormValues, [field]: value }));
  };

  const getFieldProps = <
    TField extends keyof z.input<TFormSchema>,
    TInValue extends z.input<TFormSchema>[TField],
    TOutValue extends z.output<SchemaFallback<TFormSchema>>[TField]
  >(
    fieldKey: TField
  ) => {
    const onChange = (e: OnChangeEventUnion, ...args: any | any[]) => {
      // @note This conditional handles the event-type from MUI Check-Box's `onChange`.
      if (e && args.length === 1 && typeof args[0] === "boolean") {
        setField(fieldKey, args[0] as TOutValue);
      }

      // @note This conditional handles the event-type from MUI Date-Picker's `onChange`. Same logic applies for `null` values.
      else if (isDayjs(e) || isNull(e)) {
        setField(fieldKey, (e as TInValue) ?? null);
        // @ts-ignore
      } else if (e?.target?.value === undefined && (e as RadioGroupChangeEvent).value) {
        setField(fieldKey, (e as RadioGroupChangeEvent).value ?? null);
      } else {
        // @ts-ignore
        setField(fieldKey, e.target.value ?? null);
      }
    };

    const errorsInit = validation.error?.formErrors.fieldErrors as
      | { [P in keyof z.output<TFormSchema>]?: string[] | undefined }
      | undefined;
    const errors = errorsInit && errorsInit[fieldKey]?.join(",\n");

    const value: TOutValue | null = form[fieldKey] ?? null;

    return {
      onChange,
      errors,
      value,
    };
  };
  // ----------------- Utilities (above) -----------------

  // ----------------- Getters (below) -----------------
  const validation: z.SafeParseReturnType<
    z.input<TFormSchema>,
    z.output<TFormSchema>
  > = baseFormSchema.safeParse(form);
  const errors = validation.error?.formErrors.fieldErrors as
    | { [P in keyof TFormSchema["shape"]]?: string[] | undefined }
    | undefined;
  const isValid = validation.success;
  const isDirty = !isEqual(referenceFormValues, form);
  // ----------------- Getters (above) -----------------

  useEffect(() => {
    const isRefFormInit = isEqual(initializedForm, referenceFormValues);
    const isFormInit = isEqual(initializedForm, form);

    // Set form after once when default-form-values is available
    if (isRefFormInit && isFormInit && defaultFormValues !== null) {
      resetToDefault(schemaWithDefaults.parse(defaultFormValues ?? {}));
    }

    // @todo Apply updated default-form-values to non-dirty fields (requires defining `dirtyValues` object)
  }, [JSON.stringify(defaultFormValues)]);

  return {
    form,
    setForm,
    setField,
    validation,
    errors,
    isValid,
    isDirty,
    resetToDefault,
    getFieldProps,
    schema: inputFormSchema,
  } as const;
};

export default useValidatedForm;
