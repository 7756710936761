import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioGroupInput } from '@/components/inputs/radioGroupInput/RadioGroupInput';
import RepayModal from '@/components/modals/PaymentModalGroup/RepayModal';
import OpenEdgeModal from '@/components/modals/PaymentModalGroup/OpenEdgeModal';
import { Modal } from '@/components/modals/Modal';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { Spacer } from '@/components/spacer/Spacer';
import { TextInput } from '@/components/inputs/text/TextInput';
import { ConfirmButton } from '@/components/confirmButton/ConfirmButton';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// components
// state
import { useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import { accountsService } from '@/services/accountsService';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
import { usePaymentSelector } from './paymentSlice';
// utils
import { config } from '@/config';
import {
  getAllowedPaymentTypes,
  getCanWaiveFee,
  getConvenienceFee,
  getCpiDueDate,
  getDdDueStatus,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  pollForReceipt,
} from '@/utils/helpers/payment';
import {
  exists,
  formatAddress,
  getAddressFromGoogleMaps,
  getInitials,
} from '@/utils/helpers/general';
import { lowerCaseLetters } from '@/utils/helpers/formatting';
import {
  Employee,
  GetPaymentData,
  PaymentProviders,
  PostPaymentPayload,
  getDefaultPostPaymentPayloadNew,
  paymentService,
} from '@/services/paymentService';
import { useNavBack } from '@/utils/routing/useNavBackEvent';
import { useNavigationConfirm } from '@/hooks/useNavigationConfirm/useNavigationConfirm';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { SavedPaymentMethod } from '@/interfaces/CreditCard';
import {
  CardProcessorName,
  PaymentAcceptedIn,
  PaymentType,
  TransactionType,
} from '@/enums/payment';
// style
import styles from './PaymentForm.module.scss';

/**
 * @deprecated needs refactor
 * @todo use context to manage state
 * Form state is initialized with data from getPaymentDetails
 * Most of the payment submit payload is contained in the form state
 * Some fields shown in the form are not directly part of the form state - the ones not wrapped in a <Controller />
 * e.g. totalPayment which is not part of the submit payload but needs to display
 * e.g. employee which is an object that many of the submit payload fields are populated by
 * Currently this supports the following payment types:
 * 1. Regular payments ("Take A Payment" in old accounts, collection payments), as indicated by an empty `paymentType`
 * 2. Principal Only, as indicated by `paymentType` === 'PrinOnly'
 */
const PaymentFormOnly: FC<{
  paymentType?: string;
  onSuccessfullResponse?: () => void;
}> = ({ paymentType, onSuccessfullResponse }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const navBack = useNavBack();
  const employeeRecId = useAuthSelector((s) => s.userId);
  const { postPaymentLoading } = usePaymentSelector((s) => s);
  const [paymentData, setPaymentData] = useState<GetPaymentData>({} as GetPaymentData);
  const [paymentProviderData, setPaymentProviderData] = useState<PaymentProviders>(
    {} as PaymentProviders
  );
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [paymentDataError, setPaymentDataError] = useState('');
  const [enabledProviders, setEnabledProviders] = useState<CardProcessorName[]>([]);
  const [transactionType, setTransactionType] = useState<TransactionType>('Payment');
  const [totalPayment, setTotalPayment] = useState(0);
  const [cpiDueDate, setCpiDueDate] = useState('');
  const [provider, setProvider] = useState<CardProcessorName>();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<SavedPaymentMethod[]>([]);
  const [useSavedPaymentMethod, setUseSavedPaymentMethod] = useState<0 | 1>(0);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState<SavedPaymentMethod>();
  const [repayIframeUrl, setRepayIframeUrl] = useState('');
  const [openEdgeCCModalOpen, setOpenEdgeCCModalOpen] = useState(false);
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [showPendingRewriteModal, setShowPendingRewriteModal] = useState(false);
  const [cancellingRewrite, setCancellingRewrite] = useState(false);
  const [fullBillingAddress, setFullBillingAddress] = useState<string | undefined>(undefined);

  const realSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const isPrincipalOnly = paymentType === 'PrinOnly';
  const isRegularPayment = !paymentType;
  const showCpi = isRegularPayment && paymentData.onCpi;
  const showLateCharge = isRegularPayment && !!paymentData.lcDue;
  const showNsfCharge = isRegularPayment && !!paymentData.nsfDue;
  const showDDCharge = isRegularPayment && !!paymentData.defDownBal;

  const init = async () => {
    try {
      setPaymentDataLoading(true);
      setPaymentDataError('');
      const paymentDataResponse = await paymentService.getPaymentDetails(colRecId!);
      setPaymentData(paymentDataResponse);

      const defaultPayload = getDefaultPostPaymentPayloadNew(paymentDataResponse);

      if (isPrincipalOnly) {
        defaultPayload.CpiPaid = 0;
        defaultPayload.CarPmt = 0;
        defaultPayload.DdPmt = 0;
        defaultPayload.LcPaid = 0;
        defaultPayload.LcOwed = undefined;
        defaultPayload.NsfPaid = 0;
        defaultPayload.PaymentType = 'PrinOnly';
        defaultPayload.TotalReceived = 0;
        defaultPayload.PmtContext = 'NEW_UI_MISC';
        setTotalPayment(0);
      }

      if (isRegularPayment) {
        setTotalPayment(paymentDataResponse.dmsNextDueAmount);
      }

      reset(defaultPayload);

      const paymentProviders = await paymentService.getPaymentProviders(colRecId!);
      const enabledProviders = getPaymentProviderArray(paymentProviders);
      const preferredProviderName = getPreferredPaymentProviderName(
        paymentProviders.preferredPaymentProvider,
        enabledProviders
      );
      setPaymentProviderData(paymentProviders);
      setEnabledProviders(enabledProviders);
      setProvider(preferredProviderName);

      const paymentMethods = await paymentService.getSavedPaymentMethods(
        paymentDataResponse.appRecId
      );
      setSavedPaymentMethods(paymentMethods ?? []);

      const users = await paymentService.getUsersByCompanyId(paymentDataResponse.compId);
      setEmployees(users);
    } catch (err) {
      console.error(err);
      setPaymentDataError('Unable to load payment data');
    } finally {
      setPaymentDataLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [colRecId]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<PostPaymentPayload>({
    defaultValues: getDefaultPostPaymentPayloadNew(paymentData),
  });

  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  // These fields are already defaulted in getDefaultPostPaymentPayload, but without providing default values it turns them into <T> | undefined
  // Probably because the form fields could be deleted, turning them into undefined
  // Just defaulting them again here so it doesn't break any calculations
  const {
    PaidIn,
    CarPmt = 0,
    CpiPaid = 0,
    DdPmt = 0,
    NsfPaid = 0,
    LcPaid = 0,
    LcWaived = 0,
    LcOwed = 0,
    PaidBy,
    ConvFee = 0,
    TotalReceived = 0,
    AchAcctType = 0,
    Mpd = {},
    WaiveConvFee,
  } = useWatch({ control });

  const totalPaymentErrors =
    !totalPayment || totalPayment < 0
      ? 'Total Payment must be a positive number'
      : totalPayment > paymentData.maxPayment
      ? `Total Payment cannot exceed maximum payment of ${paymentData.maxPayment}`
      : '';

  useEffect(() => {
    // Set the default employee once we have a list of employees
    if (!employees.length) return;
    const defaultEmployee = employees.find((emp) => emp.recId === employeeRecId);
    if (defaultEmployee) {
      setEmployee(defaultEmployee);
    } else {
      setEmployee(employees[0]);
    }
    // @todo deps arr must use stable ref
  }, [employees]);

  useEffect(() => {
    // Employee object was updated by dropdown or useEffect defaulting - update the relevant submit payload fields
    if (!employee) return;
    setValue('TakenBy', employee.shortName);
    setValue('UserEmail', employee.userId);
    setValue('UserRecId', employee.recId);
    // @todo deps arr must use stable ref
  }, [employee]);

  const isCC = PaidBy === PaymentType.CreditCard;
  const isACH = PaidBy === PaymentType.Ach;

  useEffect(() => {
    if (
      paymentData?.cpiRate &&
      paymentData?.cpiSchedule &&
      exists(paymentData?.cpiTotalPaid) &&
      paymentData?.cpiFirstDueDate
    ) {
      const newDueDate = getCpiDueDate(
        paymentData.cpiRate,
        paymentData.cpiSchedule,
        paymentData.cpiTotalPaid!,
        paymentData.cpiFirstDueDate,
        CpiPaid || 0
      );
      setCpiDueDate(newDueDate);
    }
  }, [CpiPaid]);

  useEffect(() => {
    if (isPrincipalOnly) return;
    // Recalculate late charges when amount to pay changes
    if (!exists(LcPaid) || 1 / LcPaid! === -Infinity) {
      // prevent undefined and -0
      setValue('LcPaid', 0);
    }

    const maxLateCharge = paymentData.lcDue ?? 0;
    const paid = LcPaid ?? 0;
    const waived = LcWaived ?? 0;
    const owed = LcOwed ?? 0;

    let newPaid = paid;
    let newWaived = waived;
    let newOwed = owed;

    if (paid < 0) {
      newPaid = 0;
      newWaived = 0;
    } else if (paid > maxLateCharge) {
      newPaid = maxLateCharge;
      newWaived = 0;
    } else if (paid + waived > maxLateCharge) {
      newWaived = maxLateCharge - newPaid;
    }

    newOwed = maxLateCharge - paid - waived;

    if (newPaid !== paid) {
      setValue('LcPaid', newPaid);
    }

    if (newWaived !== waived) {
      setValue('LcWaived', newWaived);
    }

    if (newOwed !== owed) {
      setValue('LcOwed', newOwed);
    }
  }, [LcPaid]);

  useEffect(() => {
    if (isPrincipalOnly) return;
    // recalculate late charges when amount to waive changes
    if (!exists(LcWaived) || 1 / LcWaived! === -Infinity) {
      // prevent undefined and -0
      setValue('LcWaived', 0);
    }

    const maxLateCharge = paymentData.lcDue ?? 0;
    const paid = LcPaid ?? 0;
    const waived = LcWaived ?? 0;
    const owed = LcOwed ?? 0;

    let newPaid = paid;
    let newWaived = waived;
    let newOwed = owed;

    if (waived > maxLateCharge) {
      newPaid = 0;
      newWaived = maxLateCharge;
    } else if (waived < 0) {
      newPaid = maxLateCharge;
      newWaived = 0;
    } else if (paid + waived > maxLateCharge) {
      newPaid = maxLateCharge - waived;
    }

    newOwed = maxLateCharge - paid - waived;

    if (newPaid !== paid) {
      setValue('LcPaid', newPaid);
    }

    if (newWaived !== waived) {
      setValue('LcWaived', newWaived);
    }

    if (newOwed !== owed) {
      setValue('LcOwed', newOwed);
    }
  }, [LcWaived]);

  useEffect(() => {
    // Add conv fee if needed for payment type
    if (isCC || isACH) {
      setValue('TotalReceived', totalPayment + (ConvFee ?? 0));
      trigger('TotalReceived');
    }
    if (totalPayment > TotalReceived) {
      setValue('TotalReceived', totalPayment);
      trigger('TotalReceived');
    }
  }, [totalPayment, isCC, isACH]);

  useEffect(() => {
    // Can't have change due if paying with card / ACH
    const totalPaid = isPrincipalOnly ? CarPmt : totalPayment;
    if (isCC || isACH) {
      setValue('Change', 0);
    } else if (TotalReceived > totalPayment) {
      setValue('Change', TotalReceived - totalPaid);
    } else {
      setValue('Change', 0);
    }
  }, [TotalReceived, totalPayment, PaidBy]);

  useEffect(() => {
    // In regular payments, CarPmt is not a user-editable field - it depends on the values in the other fields
    // For principal only payment, it is editable
    if (isPrincipalOnly) return;
    setValue('CarPmt', totalPayment - (CpiPaid + LcPaid + DdPmt + NsfPaid));
    trigger('CarPmt');
  }, [totalPayment, CpiPaid, LcPaid, DdPmt, NsfPaid, TotalReceived]);

  useEffect(() => {
    // TotalReceived is user-editable but needs to be recalculated on changes to other fields
    const newTotalReceived = CpiPaid + DdPmt + LcPaid + NsfPaid + CarPmt;
    if ((isCC || isACH) && !WaiveConvFee) {
      setValue('TotalReceived', newTotalReceived + ConvFee);
    } else {
      setValue('TotalReceived', newTotalReceived);
    }
    trigger('TotalReceived');
  }, [CpiPaid, DdPmt, LcPaid, NsfPaid, CarPmt, ConvFee, isCC, isACH, WaiveConvFee]);

  useEffect(() => {
    if (!paymentData || !provider) return;
    setValue('ConvFee', getConvenienceFee(paymentData!, provider, PaidBy!, !!WaiveConvFee));
    setValue('WaiveConvFee', !!WaiveConvFee);
    setValue('IsAch', isACH);

    // Handle the possibility that they could have entered OE ACH details and then changed the payment method / provider
    if (provider !== CardProcessorName.OpenEdge || PaidBy !== PaymentType.Ach) {
      setValue('Mpd.AccountNumber', undefined);
      setValue('Mpd.RoutingNumber', undefined);
    }

    // None of these fields should matter but just to be safe, keep them up to date with the current selections
    setValue('WaiveAchConvFee', !!WaiveConvFee);
    setValue('WaiveCCConvFee', !!WaiveConvFee);
    setValue(
      'CcConvFee',
      isCC ? getConvenienceFee(paymentData!, provider, PaymentType.CreditCard, !!WaiveConvFee) : 0
    );
    setValue(
      'AchConvFee',
      isACH ? getConvenienceFee(paymentData!, provider, PaymentType.Ach, !!WaiveConvFee) : 0
    );
    // @todo deps arr must use stable ref
  }, [paymentData, provider, PaidBy, WaiveConvFee]);

  useEffect(() => {
    setValue('IsNewCard', isCC || isACH ? !useSavedPaymentMethod : false);
    setValue('SaveCard', isCC || isACH ? !useSavedPaymentMethod : false);
    if (useSavedPaymentMethod && savedPaymentMethod) {
      setValue('BillFirstName', savedPaymentMethod.fName ?? '');
      setValue('BillLastName', savedPaymentMethod.lName ?? '');
      setValue('BillAddress', savedPaymentMethod.address ?? '');
      setValue('BillCity', savedPaymentMethod.city ?? '');
      setValue('BillState', savedPaymentMethod.state ?? '');
      setValue('BillZip', savedPaymentMethod.zip ?? '');
      setValue('Mpd.Token', savedPaymentMethod.mpdId!);
      setFullBillingAddress(
        formatAddress(
          savedPaymentMethod.address,
          savedPaymentMethod.city,
          savedPaymentMethod.state,
          savedPaymentMethod.zip
        )
      );
    } else if (Mpd.Token) {
      // Only clear out these values if a saved payment method was already selected (which we can tell based on Mpd.Token being set)
      // Otherwise we're clearing out default values when we don't want to
      setValue('BillFirstName', '');
      setValue('BillLastName', '');
      setValue('BillAddress', '');
      setValue('BillCity', '');
      setValue('BillState', '');
      setValue('BillZip', '');
      setValue('Mpd.Token', null);
      setSavedPaymentMethod(undefined);
      setFullBillingAddress(undefined);
    }
  }, [savedPaymentMethod, useSavedPaymentMethod, PaidBy]);

  useEffect(() => {
    if (!paymentData || isPrincipalOnly) return;
    // "payoff" is meant if they want to pay off the car paymentData.maxPayment
    if (transactionType === 'Payoff') {
      setTotalPayment(paymentData.maxPayment);
      setValue('CarPmt', paymentData.payOff);
      setValue('CpiPaid', paymentData.cpiDueNow);
      setValue('LcPaid', paymentData.lcDue);
      setValue('DdPmt', paymentData.ddDueNow);
      setValue('NsfPaid', paymentData.nsfDue);
      setValue('TotalReceived', paymentData.maxPayment);
    } else {
      setTotalPayment(paymentData.dmsNextDueAmount);
      setValue('CarPmt', paymentData.pmtDue);
      setValue('CpiPaid', paymentData.cpiDueNow);
      setValue('LcPaid', paymentData.lcDue);
      setValue('DdPmt', paymentData.ddDueNow);
      setValue('NsfPaid', paymentData.nsfDue);
      setValue('TotalReceived', paymentData.dmsNextDueAmount);
    }
  }, [transactionType]);

  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  const paymentTypes = paymentProviderData ? getAllowedPaymentTypes(paymentProviderData) : [];

  const usableSavedPaymentMethods = savedPaymentMethods.filter((pm) => {
    return (
      pm.isActive &&
      pm.fName &&
      pm.lName &&
      pm.mpdId &&
      pm.last4 &&
      lowerCaseLetters(pm.mpdType) === lowerCaseLetters(PaidBy) &&
      lowerCaseLetters(pm.cardProcessor) === lowerCaseLetters(provider)
    );
  });

  // Event handlers
  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('BillAddress', fullAddress.address);
    setValue('BillCity', fullAddress.city);
    setValue('BillState', fullAddress.state);
    setValue('BillZip', fullAddress.zip);
  };

  const cancelRewriteAndProceed = async () => {
    setCancellingRewrite(true);
    try {
      await accountsService.cancelPendingRewrite(paymentData.appRecId);
      setShowPendingRewriteModal(false);
      realSubmitButtonRef.current!.click();
    } finally {
      setCancellingRewrite(false);
    }
  };
  const navigate = useNavigate();
  const onSuccess = async () => {
    if(onSuccessfullResponse) return onSuccessfullResponse();

    await dispatch(getAccountInformation(colRecId));
    navigate('..', { relative: 'path' });
  };

  const onSubmit = async (paymentPayload: PostPaymentPayload) => {
    if (isRegularPayment && totalPaymentErrors) return; // Not part of form state so these errors won't prevent submit by default

    const isPendingRewrite = await accountsService.getIsPendingRewrite(paymentData.appRecId);

    if (isPendingRewrite) {
      setShowPendingRewriteModal(true);
      return;
    }

    if (useSavedPaymentMethod && !Mpd.Token) {
      // This is good enough for now, an inline error would be better
      // An inline error is just clunky to implement because the saved payment state is not contained in the form
      toast.error('Select a saved payment method or enter a new payment method');
      return;
    }

    const takenBy = getInitials(employee!.shortName);
    const processorType = getProcessorIntByName(provider!);

    const finalPayload = cloneDeep(paymentPayload);
    finalPayload.TakenBy = takenBy;
    finalPayload.ProcessorType = processorType;
    finalPayload.PmtType = finalPayload.PaidBy;

    if (
      (!isCC && !isACH) ||
      finalPayload!.Mpd.Token ||
      (isACH && provider === CardProcessorName.OpenEdge)
    ) {
      // @todo use async/await
      paymentService.postPaymentSubmit(finalPayload).then((res) => {
        pollForReceipt(res.paymentLogRecId, onSuccess);
      });
    } else if (provider === CardProcessorName.Repay) {
      // @todo use async/await
      paymentService.postPaymentSubmit(finalPayload).then((res) => {
        if (!res.success) {
          toast.error('Unable to load repay payment form');
          return;
        }
        setPaymentLogRecId(res.paymentLogRecId);
        setRepayIframeUrl(res.iFrameUrl);
      });
    } else if (provider === CardProcessorName.OpenEdge && isCC) {
      try {
        const res = await paymentService.postPaymentSubmit(finalPayload!);
        if (!res.paymentLogRecId)
          throw new Error('No PaymentLogRecId returned from res', { cause: finalPayload });
        setPaymentLogRecId(res.paymentLogRecId);
        setOpenEdgeCCModalOpen(true);
      } catch (error) {
        toast.error('Error posting payment');
        console.error(error);
      }
    }
  };

  const canWaiveFee = getCanWaiveFee(provider!, paymentData);

  // @todo move nested components to separate files
  return (
    <>
      {paymentDataLoading ? (
        <Grid container direction="column" flex={1} alignItems="center">
          <Loader size="large" />
        </Grid>
      ) : paymentDataError ? (
        <div>{paymentDataError}</div>
      ) : (
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.column}>
            {enabledProviders && enabledProviders.length > 1 && (
              <DropdownInput
                label="Processor"
                data={enabledProviders}
                value={provider}
                onChange={(e) => setProvider(e.target.value as CardProcessorName)}
                errors={!provider}
              />
            )}

            {isRegularPayment && (
              <RadioGroupInput
                label="Transaction Type"
                data={TransactionType.options.map((type) => ({ label: type, value: type }))}
                layout="horizontal"
                value={transactionType}
                onChange={(e) => setTransactionType(e.value)}
              />
            )}

            <Controller
              name="PaidIn"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroupInput
                  {...field}
                  label="Paid In"
                  data={PaymentAcceptedIn.options.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  layout="horizontal"
                  value={PaidIn}
                  onChange={(e) => setValue('PaidIn', e.value)}
                />
              )}
            />

            {isRegularPayment && (
              <CurrencyInput
                label="Total Payment"
                required
                value={totalPayment}
                onChange={(e) => setTotalPayment(e.target.value || 0)}
                errors={totalPaymentErrors ?? false}
              />
            )}

            {isPrincipalOnly && (
              <CurrencyInput label="Principal Balance" readOnly value={paymentData.prinBal ?? 0} />
            )}

            <Controller
              name="CarPmt"
              control={control}
              rules={{
                min: isPrincipalOnly
                  ? { value: 0.01, message: 'Payment must be greater than 0' }
                  : { value: 0, message: 'Payment cannot be negative' },
                max: isPrincipalOnly
                  ? { value: paymentData.prinBal ?? 0, message: 'Cannot exceed principal balance' }
                  : undefined,
              }}
              render={({ field }) => (
                <CurrencyInput
                  label={isPrincipalOnly ? 'Principal Payment' : 'Car Payment'}
                  readOnly={!isPrincipalOnly}
                  errors={errors.CarPmt?.message}
                  {...field}
                />
              )}
            />

            {showCpi && (
              <Controller
                name="CpiPaid"
                control={control}
                rules={{ required: 'CPI Due is required' }}
                render={({ field }) => (
                  <CurrencyInput
                    label={`CPI Due${cpiDueDate ? ` on ${cpiDueDate}` : ''}`}
                    required
                    errors={errors.CpiPaid?.message}
                    {...field}
                  />
                )}
              />
            )}

            {showLateCharge && (
              <div className={styles.inlineInputContainer}>
                <span className={styles.inlineInputLabel}>Late Charges</span>
                <div className={styles.inlineInputItems}>
                  <Controller
                    name="LcPaid"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Paid"
                        required
                        horizontalLabel={false}
                        errors={!!errors.LcPaid}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="LcWaived"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Waived"
                        required
                        horizontalLabel={false}
                        errors={!!errors.LcWaived}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="LcOwed"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput label="Owed" readOnly horizontalLabel={false} {...field} />
                    )}
                  />
                </div>
              </div>
            )}

            {showNsfCharge && (
              <div className={styles.inlineInputContainer}>
                <span className={styles.inlineInputLabel}>NSF Charges</span>
                <div className={styles.inlineInputItems}>
                  <Controller
                    name="NsfPaid"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Paid"
                        required
                        horizontalLabel={false}
                        errors={!!errors.NsfPaid}
                        {...field}
                      />
                    )}
                  />
                  <CurrencyInput
                    label="Due"
                    readOnly
                    horizontalLabel={false}
                    value={paymentData.nsfDue}
                  />
                </div>
              </div>
            )}

            {showDDCharge && (
              <>
                <Controller
                  name="DdPmt"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CurrencyInput label={`DD Due`} required errors={!!errors.DdPmt} {...field} />
                  )}
                />
                <small style={{ fontSize: '12px', fontStyle: 'italic', marginLeft: 'auto' }}>
                  {getDdDueStatus(paymentData)}
                </small>
              </>
            )}

            <Controller
              name="PaidBy"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DropdownInput
                  {...field}
                  label="Payment Type"
                  required
                  data={paymentTypes}
                  errors={!!errors.PaidBy}
                />
              )}
            />

            {(isCC || isACH) && (
              <div className={styles.inlineInputContainer}>
                <span className={styles.inlineInputLabel}>Conv. Fee</span>
                <div className={styles.inlineInputItems}>
                  <Controller
                    name="ConvFee"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly {...field} />}
                  />
                  {canWaiveFee && (
                    <Controller
                      name="WaiveConvFee"
                      control={control}
                      render={({ field }) => <Checkbox label="Waive Fee" {...field} />}
                    />
                  )}
                </div>
              </div>
            )}

            <Controller
              name="TotalReceived"
              control={control}
              rules={{
                required: !isCC && !isACH,
                min: isPrincipalOnly
                  ? {
                      value: CarPmt,
                      message: 'Amount tendered cannot be less than the principal payment',
                    }
                  : {
                      value: totalPayment,
                      message: 'Amount tendered cannot be less than the total payment',
                    },
              }}
              render={({ field }) => (
                <CurrencyInput
                  label="Amount Tendered"
                  required={!isCC && !isACH}
                  readOnly={isCC || isACH}
                  errors={errors.TotalReceived?.message || !!errors.TotalReceived}
                  {...field}
                />
              )}
            />

            <Controller
              name="Change"
              control={control}
              render={({ field }) => <CurrencyInput label="Change Due" readOnly {...field} />}
            />

            <Spacer expand />

            {paymentData.buyerEmail && !paymentData.buyerNoEmail && (
              <Controller
                name="SendB"
                control={control}
                render={({ field }) => <Checkbox label="Send Buyer Email Receipt" {...field} />}
              />
            )}

            {paymentData.cobuyerEmail && !paymentData.cobuyerNoEmail && (
              <Controller
                name="SendC"
                control={control}
                render={({ field }) => <Checkbox label="Send Cobuyer Email Receipt" {...field} />}
              />
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.column} style={{ width: '380px' }}>
            {(isCC || isACH) && (
              <>
                {!!usableSavedPaymentMethods.length && (
                  <RadioGroupInput
                    label="Payment Method"
                    data={[
                      { label: `New ${isACH ? 'Account' : 'Card'}`, value: 0 },
                      { label: `${isACH ? 'Account' : 'Card'} on file`, value: 1 },
                    ]}
                    layout="horizontal"
                    value={useSavedPaymentMethod}
                    onChange={(e) => setUseSavedPaymentMethod(e.value)}
                  />
                )}
                {!!useSavedPaymentMethod && (
                  <DropdownInput
                    label={isACH ? 'Accounts' : 'Cards'}
                    required
                    data={usableSavedPaymentMethods}
                    dataItemKey="recId"
                    textField="last4"
                    onChange={(e) => setSavedPaymentMethod(e.value)}
                    value={savedPaymentMethod}
                  />
                )}
                {!useSavedPaymentMethod && (
                  <Controller
                    name="SaveCard"
                    control={control}
                    render={({ field }) => (
                      <Checkbox label={`Save ${isACH ? 'Account' : 'Card'}`} {...field} />
                    )}
                  />
                )}
                {(!useSavedPaymentMethod || !!savedPaymentMethod) && (
                  <>
                    <Controller
                      name="BillFirstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          label="First Name"
                          required
                          readOnly={!!useSavedPaymentMethod}
                          errors={!!errors.BillFirstName}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="BillLastName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          label="Last Name"
                          required
                          readOnly={!!useSavedPaymentMethod}
                          errors={!!errors.BillLastName}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}

            {provider === CardProcessorName.OpenEdge && isACH && !useSavedPaymentMethod && (
              <>
                <Controller
                  name="Mpd.AccountNumber"
                  control={control}
                  rules={{
                    required: true,
                    pattern: { value: /^\d{5,17}$/, message: 'Format is incorrect' },
                  }}
                  render={({ field }) => (
                    <TextInput
                      label="Account Number"
                      required
                      errors={errors.Mpd?.AccountNumber?.message || !!errors.Mpd?.AccountNumber}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="Mpd.RoutingNumber"
                  control={control}
                  rules={{
                    required: true,
                    pattern: { value: /^\d{9}$/, message: 'Format is incorrect' },
                  }}
                  render={({ field }) => (
                    <TextInput
                      label="Routing Number"
                      required
                      errors={errors.Mpd?.RoutingNumber?.message || !!errors.Mpd?.RoutingNumber}
                      {...field}
                    />
                  )}
                />
              </>
            )}

            {isCC && !useSavedPaymentMethod && (
              <>
                <Controller
                  name="BillAddress"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <AddressAutocomplete
                      label="Address"
                      required
                      errors={!!errors.BillAddress}
                      {...field}
                      onPlaceSelected={handlePlaceSelected}
                      fullAddress={fullBillingAddress}
                    />
                  )}
                />
                <Controller
                  name="BillCity"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput label="City" required errors={!!errors.BillCity} {...field} />
                  )}
                />
                <Controller
                  name="BillState"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DropdownInput
                      {...field}
                      label="State"
                      data={usaStateCodes}
                      required
                      errors={!!errors.BillState}
                    />
                  )}
                />
                <Controller
                  name="BillZip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput label="Zip" required errors={!!errors.BillZip} {...field} />
                  )}
                />
              </>
            )}

            {isACH && !useSavedPaymentMethod && (
              <>
                <Controller
                  name="AchAcctType"
                  control={control}
                  render={({ field }) => {
                    // eslint-disable-next-line
                    const { value, onChange, ...restField } = field;
                    return (
                      <RadioGroupInput
                        label="Account Type"
                        data={[
                          { label: 'Checking', value: 0 },
                          { label: 'Savings', value: 1 },
                        ]}
                        layout="horizontal"
                        value={AchAcctType}
                        onChange={(e) => setValue('AchAcctType', e.value)}
                        {...restField}
                      />
                    );
                  }}
                />
              </>
            )}
            <Spacer expand />
            <DropdownInput
              label="Employee"
              required
              data={employees}
              dataItemKey="recId"
              textField="shortName"
              onChange={(e) => setEmployee(e.value)}
              defaultValue={employee}
              value={employee}
              errors={!employee}
            />

            <Controller
              name="TakenByPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  label="Password"
                  required
                  errors={!!errors.TakenByPassword}
                  type="password"
                  {...field}
                />
              )}
            />

            <Controller
              name="PaidRef"
              control={control}
              render={({ field }) => <TextInput label="Reference #" {...field} />}
            />

            <Controller
              name="PayNote"
              control={control}
              render={({ field }) => <TextInput label="Payment Note" {...field} />}
            />

            <div className={styles.buttonContainer}>
              <ConfirmButton
                triggerElement={(onClick) => (
                  <Grid container alignSelf="end">
                    <Button
                      label="Post Payment"
                      disabled={postPaymentLoading}
                      loading={postPaymentLoading}
                      style={{ width: '211px' }}
                      onClick={onClick}
                    />
                  </Grid>
                )}
                confirmButtonProps={{ type: 'submit' }}
                cancelButtonProps={{}}
                modalContents="Please confirm that you want to post this payment"
              />
              <button type="submit" style={{ display: 'none' }} ref={realSubmitButtonRef} />
            </div>
          </div>
        </form>
      )}
      <RepayModal
        iframeUrl={repayIframeUrl}
        setIframeUrl={setRepayIframeUrl}
        paymentLogRecId={paymentLogRecId}
        onComplete={onSuccess}
      />
      <OpenEdgeModal
        apiKey={paymentData.openedgeApiKey}
        openEdgeEnv={config.openEdgeEnvironment}
        isOpen={openEdgeCCModalOpen}
        setIsOpen={setOpenEdgeCCModalOpen}
        paymentLogRecId={paymentLogRecId}
        onComplete={onSuccess}
      />
      {showPendingRewriteModal && (
        <Modal centerModal isOpen={showPendingRewriteModal}>
          <div>There is a pending rewrite on this account.</div>
          <div>In order to proceed with the transaction, the rewrite must be canceled</div>
          <Spacer size={20} />
          <div className={styles.pendingRewriteButtonContainer}>
            <Button
              label="Cancel Transaction"
              fullWidth={false}
              secondary
              onClick={() => {
                setShowPendingRewriteModal(false);
              }}
            />
            <Button
              label="Cancel Rewrite & Complete Payment"
              fullWidth={false}
              themeColor="warning"
              loading={cancellingRewrite}
              onClick={cancelRewriteAndProceed}
            />
          </div>
        </Modal>
      )}

      {NavigationConfirm}
    </>
  );
};

export default PaymentFormOnly;
