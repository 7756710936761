import { CSSProperties, FC, useEffect, useState } from "react";
import { CircleSpinner } from "react-spinners-kit";
import { toast } from "react-toastify";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DCS_BLUE } from "@/mui/theme/colors";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
// utils
import { paymentService } from "@services/paymentService";
import { displayErrors } from "@/utils/helpers/general";
import { SetState } from "@/interfaces/utilityTypes";

const LoadingSpinner: FC = () => (
  <Grid>
    <CircleSpinner color="#3299df" size={50} />
  </Grid>
);
const OpenEdgeField: FC<{ fieldId: string; label: string }> = ({ fieldId, label }) => (
  <Grid container direction="row">
    <Grid container justifyContent="center" width="100%" fontSize={14}>
      {label}
    </Grid>
    <Grid container justifyContent="center" width="100%" height="47.5px" id={fieldId} />
  </Grid>
);

const postPmt =
  (paymentLogRecId: number, setIsLoading: SetState<boolean>, onComplete?: () => void) =>
  async (tokenRes: any) => {
    setIsLoading(true);

    try {
      const pmtRes = await paymentService.postOpenEdgeCCPayment(
        tokenRes.temporary_token,
        paymentLogRecId
      );

      if (pmtRes.success) {
        const receiptUrlRes = await paymentService.getReceiptUrl(pmtRes.paymentRecId);
        window.open(receiptUrlRes);
        onComplete && onComplete();
      } else {
        toast.error("There was an issue processing your payment");
        // @todo remove once figured out
        console.error(pmtRes, tokenRes);
      }
    } finally {
      setIsLoading(false);
    }
  };

const formStyle: { [key: string]: CSSProperties } = {
  input: { height: "30px", padding: "5px 10px" },
  "input:focus": { outline: "none", border: "2px solid #4399de" },
  button: {
    height: "40px",
    color: "#fff",
    background: DCS_BLUE,
    cursor: "pointer",
    border: "none",
    borderRadius: "10px",
  },
  "button:hover": { background: "#29469590" },
};

const newCardForm = () =>
  window.GlobalPayments.ui.form({
    styles: formStyle,
    fields: {
      "card-number": {
        target: "#card-number",
        placeholder: "•••• •••• •••• ••••",
      },
      "card-expiration": {
        target: "#card-expiration",
        placeholder: "MM / YYYY",
      },
      "card-cvv": {
        target: "#card-cvv",
        placeholder: "•••",
      },
      submit: {
        target: "#submit",
        text: "Submit",
      },
    },
  });
const getOpenEdgeNewCreditCard = (
  apiKey: string | undefined | null,
  openEdgeEnv: string | undefined | null,
  paymentLogRecId: number | undefined | null,
  setIsRefreshing: SetState<boolean>,
  setPostPaymentLoading: SetState<boolean>,
  onComplete: undefined | (() => void)
) => {
  if (!apiKey || !openEdgeEnv || !paymentLogRecId) {
    toast.error("Unable to load OpenEdge payment form - API key/Environment not set");
    return;
  }
  setIsRefreshing(true);

  // Clear out the old script and fields in case this is a rerender
  document.querySelector("#openEdgeScript")?.remove();
  document.querySelectorAll("#openEdgeElements iframe")?.forEach((el) => el.remove());
  const script = document.createElement("script");
  script.src = "https://js.paygateway.com/secure_payment/v1/globalpayments.js";
  script.async = true;
  script.id = "openEdgeScript";
  script.addEventListener("load", () => {
    window.GlobalPayments.configure({
      "X-GP-Api-Key": apiKey,
      "X-GP-Environment": openEdgeEnv,
      enableAutocomplete: true,
    });

    const cardForm = newCardForm();

    cardForm.on("error", (err: any) => {
      displayErrors("There was an issue in processing your payment");
      console.error(err);
    });

    cardForm.on("token-success", postPmt(paymentLogRecId, setPostPaymentLoading, onComplete));
  });

  document.body.appendChild(script);
  setIsRefreshing(false);
};

/** @deprecated convert to `CommonModal` (MUI implementation) */
const OpenEdgeModal: FC<{
  paymentLogRecId: number | null;
  onComplete?: () => void;
  apiKey?: string;
  openEdgeEnv: string;
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
}> = ({ paymentLogRecId, onComplete, apiKey, openEdgeEnv, isOpen, setIsOpen }) => {
  const [postPaymentLoading, setPostPaymentLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getOpenEdgeNewCreditCard(
      apiKey,
      openEdgeEnv,
      paymentLogRecId,
      setRefreshing,
      setPostPaymentLoading,
      onComplete
    );
  }, [paymentLogRecId]);

  if (!isOpen) return <></>;

  return (
    <Modal
      centerModal
      isOpen={isOpen}
      closeButton
      panelChildrenStyle={{ overflow: "hidden", minWidth: "406px" }}
      onCloseButtonClick={() => setIsOpen(false)}
    >
      {refreshing ? (
        <LoadingSpinner />
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          minHeight="400px"
          fontFamily="Nunito, sans-serif"
          // @note needed for OpenEdge form
          id="openEdgeElements"
        >
          <OpenEdgeField label="Card Number" fieldId="card-number" />
          <OpenEdgeField label="Expiration Date" fieldId="card-expiration" />
          <OpenEdgeField label="CVV" fieldId="card-cvv" />

          <Spacer size={20} />

          {postPaymentLoading ? (
            <Loader size="small" />
          ) : (
            <Grid
              container
              id="submit"
              sx={{ height: "45.5px", width: "100%", justifyContent: "center" }}
            ></Grid>
          )}
        </Grid>
      )}
    </Modal>
  );
};

export default OpenEdgeModal;
