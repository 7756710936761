import { FC } from "react";
import { Controller } from "react-hook-form";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { RadioButton } from "@progress/kendo-react-inputs";
import { BasicTable } from "@/components/table/Table";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { ConfirmButton } from "@/components/confirmButton/ConfirmButton";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { MultipleInputRow } from "@/features/Sales/components/multipleInputRow/MultipleInputRow";
import { MultipleInputRowRadioButton } from "@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton";
import { TextArea } from "@/components/inputs/textarea/TextArea";
import { TextInput } from "@/components/inputs/text/TextInput";
import RepayModal from "@/components/modals/PaymentModalGroup/RepayModal";
import OpenEdgeModal from "@/components/modals/PaymentModalGroup/OpenEdgeModal";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useDownPayment } from "./useDownPayment";
// utils
import { usaStateCodes } from "@/general/regions";
import { formatCurrency } from "@/utils/helpers/general";
import { useNavigationConfirm } from "@/hooks";
import { config } from "@/config";
// interfaces
import { CardProcessorName, PaymentType } from "@/enums";
// style
import styles from "../SaleManagement.module.scss";

/** @deprecated needs refactor */
const DownPayment: FC<{
  /** @deprecated once these pages are in the router this prop will not be needed */
  setShowDownPayment: (show: boolean) => void;
}> = ({ setShowDownPayment }) => {
  const {
    downPaymentColumns,
    downPaymentList,
    showDownPaymentForm,
    onCollectClick,
    setShowDownPaymentForm,
    control,
    processors,
    acceptedInData,
    paymentTypes,
    watch,
    dataLoading,
    employeeOptions,
    handleAmountTenderedBlur,
    savedAccounts,
    savedCards,
    openEdgeModal,
    repayIframeUrl,
    paymentLogRecId,
    providerData,
    paymentDetails,
    setRepayIframeUrl,
    setOpenEdgeModal,
    errors,
    realSubmitButtonRef,
    submitDownPayment,
    handleSubmit,
    postPaymentLoading,
    initData,
    isDirty,
  } = useDownPayment();
  const cod = useSalesSelector((s) => s.saleData.sale?.cod);
  const depositDown = useSalesSelector((s) => s.saleData.sale?.depositDown);

  // todo update to also block navigation if it is currently submitting
  // this page also needs to be it's own route so that switching back to the sales management page gets blocked
  const { NavigationConfirm } = useNavigationConfirm(isDirty && showDownPaymentForm);

  const DownPaymentTable = () => (
    <div className={styles.formContainer}>
      <div className={styles.bodyContainer}>
        <div className={styles.downPaymentInfoHeader}>
          <div>
            <span className={styles.boldLabel}>Cash Down Payment:</span> {formatCurrency(cod)}
          </div>
          <div>
            <span className={styles.boldLabel}>Paid:</span>{" "}
            {formatCurrency((cod || 0) - (paymentDetails?.tOfPBal || 0))}
          </div>
          <div>
            <span className={styles.boldLabel}>Balance Due:</span>{" "}
            {formatCurrency(paymentDetails?.tOfPBal)}
          </div>
          <div className={styles.collectButton}>
            <Button
              label="Collect"
              onClick={onCollectClick}
              disabled={paymentDetails?.tOfPBal === 0}
            />
          </div>
        </div>
        <div className={styles.downPaymentInfoHeader}>
          <div>
            <span className={styles.boldLabel}>Deposit Amount:</span> {formatCurrency(depositDown)}
          </div>
          <div>
            <span className={styles.boldLabel}>Paid:</span> {formatCurrency(0)}
          </div>
          <div>
            <span className={styles.boldLabel}>Balance Due:</span> {formatCurrency(0)}
          </div>
          <div className={styles.collectButton}>
            <Button label="Collect" disabled />
          </div>
        </div>
      </div>
      <BasicTable data={downPaymentList} columns={downPaymentColumns} />
    </div>
  );

  return (
    <>
      <header className={styles.headerContainer}>
        <h2 className={styles.header}>Down Payment</h2>
        <div className={styles.buttonContainer}>
          <Button label="Sale Management" onClick={() => setShowDownPayment(false)} />
        </div>
      </header>
      {dataLoading ? (
        <div className={styles.formContainer}>
          <div className={styles.loading}>
            <Loader size="large" />
          </div>
        </div>
      ) : showDownPaymentForm ? (
        <form onSubmit={handleSubmit(submitDownPayment)} className={styles.formContainer}>
          <div className={styles.collectButton}>
            <Button
              fillMode="flat"
              label="< Go Back"
              onClick={() => setShowDownPaymentForm(false)}
            />
          </div>
          <div className={styles.bodyContainer}>
            <div className={styles.paymentContainer}>
              <Controller
                name="processor"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="Processor" data={processors} {...field} />
                )}
              />
              <Controller
                name="acceptedIn"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="Accepted-In" data={acceptedInData} {...field} />
                )}
              />
              <Controller
                name="totalPaid"
                control={control}
                rules={{
                  required: "This field is required",
                  max: {
                    value: paymentDetails!.tOfPBal!,
                    message: "Total paid cannot exceed maximum payment",
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    label="Total Paid"
                    required
                    errors={errors.totalPaid?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="paymentType"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="Payment Type" data={paymentTypes} {...field} />
                )}
              />
              <Controller
                name="referenceNumber"
                control={control}
                render={({ field }) => <TextInput label="Reference #" {...field} />}
              />
              <Controller
                name="amountTendered"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Amount Tendered"
                    rightAlignInput
                    readOnly={
                      watch("paymentType") === "Credit Card" || watch("paymentType") === "ACH"
                    }
                    {...field}
                    onBlur={(e) => handleAmountTenderedBlur(e)}
                  />
                )}
              />
              <Controller
                name="changeDue"
                control={control}
                render={({ field }) => (
                  <CurrencyInput readOnly rightAlignInput label="Change Due" {...field} />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextInput
                    required
                    label="Password"
                    type="password"
                    errors={errors.password?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="employee"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    data={employeeOptions}
                    dataItemKey="recId"
                    textField="shortName"
                    label="Employee"
                    {...field}
                  />
                )}
              />
              <Controller
                name="paymentNote"
                control={control}
                render={({ field }) => <TextArea rows={2} label="Payment Note" {...field} />}
              />
            </div>
            {watch("paymentType") === PaymentType.CreditCard ||
            watch("paymentType") === PaymentType.Ach ? (
              <div className={styles.paymentContainer}>
                <MultipleInputRow label="Fee Amount">
                  <Controller
                    name="convenienceFee"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly rightAlignInput {...field} />}
                  />
                  <Controller
                    name="waiveFee"
                    control={control}
                    render={({ field }) => (
                      <Checkbox label="Waive Fee" labelStyles={{ fontWeight: 700 }} {...field} />
                    )}
                  />
                </MultipleInputRow>
                <MultipleInputRowRadioButton label={"Payment Method"}>
                  <div>
                    <Controller
                      name="transactions"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <RadioButton
                          label={`New ${
                            watch("paymentType") === PaymentType.CreditCard ? "Card" : "Account"
                          }`}
                          defaultChecked
                          {...restProps}
                          value={0}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="transactions"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <RadioButton
                          label={`${
                            watch("paymentType") === PaymentType.CreditCard ? "Card" : "Account"
                          } on file`}
                          {...restProps}
                          value={1}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </div>
                </MultipleInputRowRadioButton>
                {watch("transactions") === 0 ? (
                  <>
                    <Controller
                      name="saveCard"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          label={`Save ${
                            watch("paymentType") === PaymentType.CreditCard ? "Card" : "Account"
                          }`}
                          labelPlacement="before"
                          {...field}
                          labelStyles={{ fontWeight: 700 }}
                        />
                      )}
                    />
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextInput
                          label="First Name"
                          required
                          errors={errors.firstName?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextInput
                          label="Last Name"
                          required
                          errors={errors.lastName?.message}
                          {...field}
                        />
                      )}
                    />
                    {watch("paymentType") === PaymentType.Ach && (
                      <MultipleInputRowRadioButton label={"Payment Method"}>
                        <div>
                          <Controller
                            name="accountType"
                            control={control}
                            render={({ field: { onChange, ...restProps } }) => (
                              <RadioButton
                                label="Checking"
                                defaultChecked
                                {...restProps}
                                value={"Checking"}
                                onChange={(e) => {
                                  onChange(e.value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <Controller
                            name="accountType"
                            control={control}
                            render={({ field: { onChange, ...restProps } }) => (
                              <RadioButton
                                label="Savings"
                                {...restProps}
                                value={"Savings"}
                                onChange={(e) => {
                                  onChange(e.value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </MultipleInputRowRadioButton>
                    )}
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextInput
                          label="Address"
                          required
                          errors={errors.address?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextInput label="City" required errors={errors.city?.message} {...field} />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <DropdownInput
                          label="State"
                          data={usaStateCodes}
                          required
                          errors={errors.state?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{
                        required: "This field is required",
                        maxLength: { value: 5, message: "Zip code invalid" },
                        minLength: { value: 5, message: "Zip code invalid" },
                      }}
                      render={({ field }) => (
                        <TextInput
                          type="number"
                          label="Zip"
                          required
                          errors={errors.zip?.message}
                          {...field}
                        />
                      )}
                    />
                    {watch("processor") === CardProcessorName.OpenEdge &&
                      watch("paymentType") === PaymentType.Ach &&
                      watch("transactions") === 0 && (
                        <>
                          <Controller
                            name="accountNumber"
                            control={control}
                            rules={{
                              required: "This field is required",
                              pattern: { value: /^\d{5,17}$/, message: "Format is incorrect" },
                            }}
                            render={({ field }) => (
                              <TextInput
                                label="Account Number"
                                required
                                errors={errors.accountNumber?.message || !!errors.accountNumber}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="routingNumber"
                            control={control}
                            rules={{
                              required: "This field is required",
                              pattern: { value: /^\d{9}$/, message: "Format is incorrect" },
                            }}
                            render={({ field }) => (
                              <TextInput
                                label="Routing Number"
                                required
                                errors={errors.routingNumber?.message || !!errors.routingNumber}
                                {...field}
                              />
                            )}
                          />
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <Controller
                      name="paymentOnFile"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <DropdownInput
                          required
                          dataItemKey="recId"
                          textField="last4"
                          label={`${
                            watch("paymentType") === PaymentType.CreditCard ? "Card" : "Account"
                          } on file`}
                          data={
                            watch("paymentType") === PaymentType.CreditCard
                              ? savedCards
                              : savedAccounts
                          }
                          errors={errors.paymentOnFile?.message}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            ) : null}
          </div>
          <div className={styles.downPaymentSubmit}>
            <ConfirmButton
              triggerElement={(onClick) => (
                <Button
                  label="Post Payment"
                  loading={postPaymentLoading}
                  disabled={postPaymentLoading}
                  onClick={onClick}
                />
              )}
              confirmButtonProps={{
                onClick: () => realSubmitButtonRef.current!.click(),
                type: "submit",
              }}
              cancelButtonProps={{}}
              modalContents="Please confirm that you want to post this payment"
            />
            <button type="submit" style={{ display: "none" }} ref={realSubmitButtonRef} />
          </div>
          <RepayModal
            iframeUrl={repayIframeUrl}
            setIframeUrl={setRepayIframeUrl}
            paymentLogRecId={paymentLogRecId}
            onComplete={() => {
              initData();
              setShowDownPaymentForm(false);
            }}
          />
          <OpenEdgeModal
            apiKey={paymentDetails!.openedgeApiKey}
            openEdgeEnv={
              providerData?.openEdgeEnv ? providerData.openEdgeEnv : config.openEdgeEnvironment
            }
            isOpen={openEdgeModal}
            setIsOpen={setOpenEdgeModal}
            paymentLogRecId={paymentLogRecId}
            onComplete={() => {
              initData();
              setOpenEdgeModal(false);
              setShowDownPaymentForm(false);
            }}
          />
        </form>
      ) : (
        <DownPaymentTable />
      )}
      {NavigationConfirm}
    </>
  );
};

export default DownPayment;
