import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";

/** Zod-field validation for `Dayjs` instances */
export const zDayjs = z.instanceof(dayjs as unknown as typeof Dayjs);
// zNullableDate
export const zDayjsNullable = z
  .preprocess((val) => (!val ? null : dayjs(val as any)), zDayjs)
  .nullable()
  .default(null);

/** ### Zod utility: Add an issue to a `superRefine`-defined refinement
 * Please see [Zod docs: `.superRefine()`](https://zod.dev/?id=superrefine) for how `.superRefine()` works.
 *
 * This enables using multiple fields throughout the schema to validate a field,
 *   while keeping the logic within the schema definition.
 *
 * ### Usage:
 * ```ts
 * // Define the schema (with fields `carPmt` & `isPrincipalOnly`)
 * z.object({
 *   carPmt: z.number().default(0),
 *   isPrincipalOnly: z.boolean().default(false),
 * }).superRefine((val, ctx) => {
 *   // Add a refinement/effect to the `carPmt` field
 *   if (val.isPrincipalOnly && val.carPmt >= 0) {
 *     zAddIssue(ctx, val, "carPmt", "Payment must be greater than 0");
 *   }
 *
 *   // ... other refinements ...
 *
 *   // return z.NEVER; // If needed, early-exit - see https://zod.dev/?id=abort-early
 * });
 * ```
 */
export const zAddIssue = <TForm extends z.output<z.ZodTypeAny>>(
  effectArgs: [TForm, z.RefinementCtx],
  /** Field name, as defined in the schema */
  path: keyof TForm extends string | number ? keyof TForm : never,
  /** Custom error message */
  message?: string,
  config?: Omit<z.IssueData, "message" | "path" | "code"> & Partial<Pick<z.IssueData, "code">>
) => {
  const [_form, ctx] = effectArgs;
  const { code, ...appliedConfig } = config ?? { code: z.ZodIssueCode.custom };

  const appliedMsg = message ?? `'${path}' field is required`;
  // @ts-ignore - @note There seems to be a bug in the type for `code` property
  const newIssue: z.IssueData = { ...appliedConfig, message: appliedMsg, path: [path], code };

  ctx.addIssue(newIssue);
};
