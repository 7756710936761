import { useEffect } from 'react';
// components
import {
  DmsNumberInput,
  DmsCheckbox,
  DmsRadioButtonGroup,
  DmsTextInput,
  DmsDropDown,
} from '@components/old';
// utils
import { lowerCaseLetters } from '@/utils/helpers/formatting';
// interfaces
import { SavedPaymentMethod } from '@/interfaces/CreditCard';
import { AchAccountType } from '@/enums/payment';
// style
import styles from './SavedACH.module.scss';

type CustomValue = { display: string; value: string };
export interface SavedACHProps {
  convenienceFee?: number;
  waiveFee: boolean;
  saveAccount: boolean;
  newAccount: boolean;
  firstName?: string;
  lastName?: string;
  savedPaymentMethods: SavedPaymentMethod[];
  mpdId?: string;
  useAchOnFile: boolean;
  paymentProvider: string;
  canWaiveFee: boolean;
  onWaiveFee: (v: boolean) => void;
  onSaveAccount: (v: boolean) => void;
  onNewAccount: (v: boolean) => void;
  onFirstName: (v: string) => void;
  onLastName: (v: string) => void;
  onAccountType: (v: string) => void;
  onMpdId?: (v: string) => void; // if not present, cannot save payment methods or have saved payment methods (misc payment)
  onSetFormErrors: (v: boolean) => void;
}

export const SavedACH: React.FunctionComponent<SavedACHProps> = (props) => {
  const {
    convenienceFee,
    waiveFee,
    saveAccount,
    newAccount,
    firstName,
    lastName,
    savedPaymentMethods = [],
    mpdId,
    useAchOnFile,
    paymentProvider,
    onWaiveFee,
    onSaveAccount,
    onNewAccount,
    onFirstName,
    onLastName,
    onAccountType,
    onMpdId,
    onSetFormErrors,
  } = props;

  const canSavePaymentMethod = !!onMpdId;

  const savedAch = savedPaymentMethods.filter((pm) => {
    // Probably just bad old testing data, but I've seen some bunk saved payment methods. Filter them out
    return (
      pm.isActive &&
      pm.mpdType === 'Ach' &&
      pm.fName &&
      pm.lName &&
      pm.mpdId &&
      pm.last4 &&
      lowerCaseLetters(pm.cardProcessor) === lowerCaseLetters(paymentProvider)
    );
  });

  useEffect(() => {
    if (!canSavePaymentMethod) return;
    if (newAccount) {
      onMpdId('');
    } else if (savedAch.length && savedAch[0]?.mpdId) {
      onMpdId(savedAch[0].mpdId);
    }
  }, [newAccount, paymentProvider]);

  useEffect(() => {
    if (newAccount && canSavePaymentMethod) {
      onSaveAccount(true);
    }
  }, []);

  useEffect(() => {
    if (!firstName || !lastName) {
      onSetFormErrors(true);
    } else {
      onSetFormErrors(false);
    }
  }, [firstName, lastName]);

  const handleErrorMessage = (input: string | number | undefined) => {
    if (!input) {
      return 'Field cannot be empty';
    } else {
      return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>ACH Convenience Fee</div>
      <div className={styles.inputRow}>
        <DmsNumberInput
          inputLabel="Fee Amount"
          inputStyles={{ width: '70px' }}
          value={convenienceFee}
          fixedDecimalScale
          decimalScale={2}
          readOnly
        />
        {props.canWaiveFee && (
          <DmsCheckbox
            checkboxLabel="Waive Fee"
            onChange={() => onWaiveFee(!waiveFee)}
            checked={waiveFee}
          />
        )}
      </div>
      {useAchOnFile && canSavePaymentMethod && (
        <DmsRadioButtonGroup
          inputLabel="New or Saved"
          buttons={AchAccountType.options}
          radioButtonGroupName="newOrSaved"
          required
          onChange={(e) => {
            if (e.target.value === AchAccountType.enum['New Account']) {
              onNewAccount(true);
            } else {
              onNewAccount(false);
            }
          }}
        />
      )}
      {newAccount && (
        <div className={styles.flexGap}>
          {canSavePaymentMethod && (
            <DmsCheckbox
              checkboxLabel="Save Account"
              onChange={() => onSaveAccount(!saveAccount)}
              checked={saveAccount}
            />
          )}
          <div className={styles.subHeader}>Name on Account</div>
          <DmsTextInput
            inputLabel="First Name"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onFirstName(e.target.value)}
            value={firstName}
            errorMessage={handleErrorMessage(firstName)}
          />
          <DmsTextInput
            inputLabel="Last Name"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onLastName(e.target.value)}
            value={lastName}
            errorMessage={handleErrorMessage(lastName)}
          />
          <div className={styles.subHeader}>Account Type</div>
          <DmsRadioButtonGroup
            inputLabel="Account"
            buttons={['Checking', 'Savings']}
            radioButtonGroupName="accountType"
            required
            onChange={(e) => {
              if (e.target.value === 'Checking') {
                onAccountType('Checking');
              } else {
                onAccountType('Savings');
              }
            }}
          />
        </div>
      )}
      {!newAccount && canSavePaymentMethod && (
        <div className={styles.flexGap}>
          <div className={styles.subHeader}>Accounts on File</div>
          {savedAch && savedAch.length > 0 && (
            <DmsDropDown
              label="Account"
              customValues={savedAch.map(
                (x) => ({ display: x.last4, value: x.mpdId } as CustomValue)
              )}
              inputContainerStyle={{ width: '100%' }}
              value={mpdId}
              onChange={(e) => onMpdId(e.target.value)}
            />
          )}
          {savedAch && savedAch.length === 0 && <div>No accounts on file.</div>}
        </div>
      )}
    </div>
  );
};
