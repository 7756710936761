import { CSSProperties, FC } from "react";
// mui
// kendo
// components
import { AccountsMainPanel } from "@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel";
// state
// utils
import { useNavigationConfirm } from "@/hooks/useNavigationConfirm/useNavigationConfirm";
// interfaces
// style
import PaymentFormOnly from "./form";

/**
 * @deprecated needs refactor
 * @todo use context to manage state
 * Form state is initialized with data from getPaymentDetails
 * Most of the payment submit payload is contained in the form state
 * Some fields shown in the form are not directly part of the form state - the ones not wrapped in a <Controller />
 * - `totalPayment` which is not part of the submit payload but needs to display
 * - `employee` which is an object that many of the submit payload fields are populated by
 * Currently this supports the following payment types:
 * 1. Regular payments ("Take A Payment" in old accounts, collection payments), as indicated by an empty `paymentType`
 * 2. Principal Only, as indicated by `paymentType` === 'PrinOnly'
 */
const PaymentForm: FC<{
  paymentType?: string;
  showTitle?: boolean;
  containerStyle?: CSSProperties;
}> = ({ paymentType, showTitle = true, containerStyle = {} }) => {
  const isPrincipalOnly = paymentType === "PrinOnly";
  const { NavigationConfirm } = useNavigationConfirm(true);

  // @todo move nested components to separate files
  return (
    <AccountsMainPanel
      navBarTitle={showTitle ? (isPrincipalOnly ? "Principal Only Payment" : "Take a Payment") : ""}
      containerStyle={containerStyle}
    >
      <PaymentFormOnly paymentType={paymentType} />
      {NavigationConfirm}
    </AccountsMainPanel>
  );
};

export default PaymentForm;
