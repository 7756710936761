import { CSSProperties, FC, PropsWithChildren, ReactNode } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { BG_HIGHLIGHT, BLUE_LIGHT } from "@/mui/theme/colors";
// components
import { Icons } from "@/components/icons";

/** @deprecated convert to MUI button and use mui theme variants */
const CloseButton: FC<{ handleClose?: () => void; closeButton?: boolean }> = ({
  handleClose,
  closeButton,
}) => {
  if (!closeButton) return <></>;

  return (
    <Grid
      container
      direction="column"
      sx={{
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "48px",
        top: "15px",
        right: "15px",
        cursor: "pointer",
        borderRadius: "50%",
        "&:hover": { background: BG_HIGHLIGHT },
        "&:active": { background: BLUE_LIGHT },
      }}
      onClick={handleClose}
    >
      <Icons.X color="#000" />
    </Grid>
  );
};

const background: SxProps = {
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 3,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 1,
};
const panelBase: SxProps = { backgroundColor: "white", opacity: 1, paddingTop: "50px" };
const panel: SxProps = {
  ...panelBase,
  right: 0,
  top: 0,
  bottom: 0,
  position: "absolute",
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  minWidth: "40%",
};
const panelCenter: SxProps = {
  ...panelBase,
  position: "relative",
  borderRadius: "20px",
  maxHeight: "$panelMaxHeight",
  overflow: "hidden",
};

/** @deprecated use `CommonModal` */
export const Modal: FC<
  PropsWithChildren & {
    onDimmerClick?: () => void;
    onCloseButtonClick?: () => void;
    title?: string;
    isOpen: boolean;
    centerModal?: boolean;
    dimmerStyle?: CSSProperties;
    panelStyle?: CSSProperties;
    panelChildrenStyle?: CSSProperties;
    closeButton?: boolean;
    headerButton?: ReactNode;
    backButton?: ReactNode;
  }
> = ({
  children,
  onDimmerClick,
  onCloseButtonClick,
  title,
  isOpen,
  centerModal,
  dimmerStyle,
  panelStyle,
  panelChildrenStyle,
  closeButton = false,
  headerButton,
  backButton,
}) => {
  if (!isOpen) return <></>;
  const appliedPanelStyle = centerModal ? panelCenter : panel;

  return (
    <Grid container direction="column" sx={background}>
      <Grid
        sx={{
          position: "absolute",
          backgroundColor: "#575757",
          opacity: 0.5,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          cursor: onDimmerClick ? "pointer" : undefined,
          ...dimmerStyle,
        }}
        onClick={onDimmerClick}
      />
      <Grid sx={{ ...appliedPanelStyle, ...panelStyle }}>
        {!!title && (
          <Grid
            container
            direction="column"
            sx={{
              justifyContent: "space-between",
              gap: "53px",
              paddingLeft: "53px",
              paddingRight: "53px",
              height: "80px",
            }}
          >
            <Grid container direction="row" sx={{ flex: 1, justifyContent: "space-between" }}>
              {backButton}

              <Grid sx={{ fontWeight: 700, fontSize: 31, lineHeight: 42, color: "#000" }}>
                {title}
              </Grid>
            </Grid>

            <Grid container>
              {headerButton}
              <CloseButton closeButton={closeButton} handleClose={onCloseButtonClick} />
            </Grid>
          </Grid>
        )}

        {!title && <CloseButton closeButton={closeButton} handleClose={onCloseButtonClick} />}

        <Grid sx={{ px: "53px", pb: "50px", overflowY: "auto", ...panelChildrenStyle }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
