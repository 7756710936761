import { z } from "zod";
// interfaces
import { FieldFallback, SchemaFallbackShape } from "./interfaces";

export const getFieldDefaultValue = <TVal, TField extends z.ZodType<TVal>, TInput>(
  zField: TInput extends z.ZodDefault<TField> ? z.ZodDefault<TField> : TField
): TField extends z.ZodDefault<infer U> ? U : null =>
  zField instanceof z.ZodDefault ? zField._def.defaultValue() : null;

/**
 * - If field already has a catch, early exit
 * - If field has a default, add `catch(defaultValue)`
 * - If field has no default (`getFieldDefaultValue` returns `null`), add null-catch
 */
export const getFieldFallbackSchema = <
  TField extends z.ZodType<T>,
  T,
  // Inferred output types
  TOut extends FieldFallback<TField>
>(
  zField: TField
) => {
  if (zField instanceof z.ZodCatch) return zField as TOut;
  if (zField instanceof z.ZodDefault) return zField.catch(getFieldDefaultValue(zField)) as TOut;
  return zField.nullable().catch(null) as TOut;
};

/** Inspired by this [StackOverflow answer](https://stackoverflow.com/a/77720528) */
export const buildDefaultSchema = <
  T extends z.ZodRawShape,
  TSchema extends z.ZodObject<T>,
  TField extends keyof SchemaFallbackShape<TSchema>
>(
  schema: TSchema
) => {
  const schemaFields = Object.entries(schema.shape) as [TField, TSchema["shape"][TField]][];

  const newProps = schemaFields.reduce((acc, [key, field]) => {
    acc[key] = getFieldFallbackSchema(field);
    return acc;
  }, {} as SchemaFallbackShape<TSchema>);

  return z.object(newProps);
};
