import { Button, TextInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from '@/components/icons';
import { DocumentsNoticesItem, ScannedDocument } from '@/interfaces';
import { accountsService } from '@/services/accountsService';
import { FC, useEffect, useState } from 'react';
import styles from './PrintDocuments.module.scss';
import scannedStyles from './ScannedDocuments.module.scss';
import { useParams } from 'react-router-dom';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { Grid, GridColumn, Loader } from '@progress/kendo-react-all';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { salesService } from '@/services/salesService';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
import { Column } from '@/components/table/TableInterface';
import { formatDate } from '@/utils/helpers/general';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useAuthSelector } from '@/features/auth/authSlice';

const DateCell: FC = (props: any) => {
  const value = props.dataItem[props.field];
  return <td>{formatDate(value as string)}</td>;
};

export const PrintDocuments = () => {
  const params = useParams();
  const { accountInformation } = useAccountSelector((s) => s);
  const { orgId, compId, locId } = useAuthSelector((s) => s);
  const [docsPrinting, setDocsPrinting] = useState<number[]>([]);
  const [documents, setDocuments] = useState<DocumentsNoticesItem[]>([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [scannedDocsLoading, setScannedDocsLoading] = useState(false);
  const [scannedDocModalOpen, setScannedDocModalOpen] = useState(false);
  const [scannedDocsList, setScannedDocsList] = useState<ScannedDocument[]>([]);
  const [scannedFileDesc, setScannedFileDesc] = useState('');
  const [scannedFile, setScannedFile] = useState<File | null>(null);
  const [uploadingScannedFile, setUploadingScannedFile] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);

  const colRecId = Number(params.colRecId);

  useEffect(() => {
    loadDocuments();
    loadScannedDocs();
  }, []);

  const loadDocuments = async () => {
    setScannedDocsLoading(true);
    try {
      const res = await accountsService.getDocumentsNoticesList(colRecId);
      setDocuments(res);
    } finally {
      setDocumentsLoading(false);
    }
  };

  const loadScannedDocs = async () => {
    setScannedDocsLoading(true);
    try {
      if (Number(accountInformation?.appRecId) < 1) {
        return;
      }
      const res = await salesService.getScannedDocs(orgId!, Number(accountInformation?.appRecId));
      setScannedDocsList(res ?? []);
    } finally {
      setScannedDocsLoading(false);
    }
  };

  const uploadScannedFile = async () => {
    if (!scannedFile) {
      setFileInputError(true);
      return;
    }
    if (!scannedFileDesc) {
      setFileDescriptionError(true);
      return;
    }

    const payload = {
      file: scannedFile!,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: accountInformation?.appRecId ?? 0,
      vehRecId: accountInformation?.vehRecId ?? 0,
      fileDesc: scannedFileDesc,
    };

    setUploadingScannedFile(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadScannedDocs(); // Refresh the list after upload
      setScannedFile(null);
      setScannedFileDesc('');
      setScannedDocModalOpen(false);
    } finally {
      setUploadingScannedFile(false);
    }
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setScannedFileDesc(e.target.value);
  };

  const handleScannedDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setScannedFile(file);
  };

  const handlePrint = async (formId: number, colRecId: number) => {
    setDocsPrinting((currPrinting) => [formId, ...currPrinting]);
    await accountsService.printOneDoc(formId, colRecId);
    setDocsPrinting((currPrinting) => currPrinting.filter((id) => id !== formId));
  };

  const ScannedDocPrintButtonCell: FC = (props: any) => {
    const [printing, setPrinting] = useState(false);
    const value = props.dataItem;

    const handlePrintScannedDoc = async () => {
      setPrinting(true);
      try {
        await salesService.printScannedDoc(orgId!, value.fileNameCloud);
      } catch (error) {
        console.error(error);
      } finally {
        setPrinting(false);
      }
    };

    return (
      <td className="k-table-td">
        {printing ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handlePrintScannedDoc} className={styles.tableActionIconContainer}>
            <Icons.Print className={styles.print} />
          </div>
        )}
      </td>
    );
  };

  const DeleteButtonCell: FC = (props: any) => {
    const value = props.dataItem;
    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
      setDeleting(true);
      try {
        await salesService.deleteScannedDoc(orgId!, value.recId);
        loadScannedDocs();
      } catch (error) {
        console.error(error);
      } finally {
        setDeleting(false);
      }
    };

    return (
      <td className="k-table-td">
        {deleting ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handleDelete} className={styles.tableActionIconContainer}>
            <Icons.X style={{ color: 'red' }} />
          </div>
        )}
      </td>
    );
  };

  const scannedDocumentsColumns: Column[] = [
    {
      field: 'fileDesc',
      title: 'Document Description',
    },
    {
      field: 'fileUploadedBy',
      title: 'Uploaded By',
    },
    {
      field: 'fileUploadedDT',
      title: 'Upload Date',
      cells: { data: DateCell },
    },
    {
      field: 'print',
      title: ' ',
      cells: { data: ScannedDocPrintButtonCell },
      width: 60,
    },
    {
      field: 'delete',
      title: ' ',
      cells: { data: DeleteButtonCell },
      width: 60,
    },
  ];

  const scannedDocColumnElems = scannedDocumentsColumns.map((column, index) => (
    <GridColumn
      key={index}
      field={column.field}
      title={column.title}
      cells={column.cells}
      width={column.width}
    />
  ));

  if (!documentsLoading && !documents.length) return <div>Unable to load documents</div>;

  const firstColumnCutoff = Math.ceil(documents.length / 2);

  const formatDocument = (doc: DocumentsNoticesItem) => (
    <div className={styles.form}>
      <span>{doc.saleDocName}</span>
      {!!doc.showPrint && (
        <Button
          label="Print"
          className={styles.printButton}
          onClick={() => handlePrint(doc.formRecId!, colRecId)}
          loading={docsPrinting.includes(doc.formRecId!)}
        />
      )}
    </div>
  );

  return (
    <AccountsMainPanel navBarTitle="Forms & Documents" loading={documentsLoading}>
      <Grid2 container direction="row" flex={1} gap={1}>
        <Grid2 container direction="column" flex={1} className={styles.formsListContainer}>
          <div className={styles.formsListTitle}>FORM TO PRINT</div>
          <Grid2 container direction="row" flex={1} className={styles.formsList}>
            <div className={styles.column}>
              {documents.slice(0, firstColumnCutoff).map((doc) => formatDocument(doc))}
            </div>
            <div className={styles.column}>
              {documents.slice(firstColumnCutoff).map((doc) => formatDocument(doc))}
            </div>
          </Grid2>
        </Grid2>
        <Grid2 container className={scannedStyles.container}>
          <Grid2 container className={scannedStyles.body}>
            <section className={scannedStyles.section}>
              <SectionHeader showBar title="Scanned Documents" />
              <div className={scannedStyles.buttonContainer}>
                <Button label="Upload New" onClick={() => setScannedDocModalOpen(true)} />
              </div>
              <Spacer size={25} />
              <div className={styles.grid}>
                <Grid data={scannedDocsList}>{scannedDocColumnElems}</Grid>
              </div>
            </section>
          </Grid2>
          {scannedDocModalOpen && (
            <Modal
              isOpen={true}
              onCloseButtonClick={() => setScannedDocModalOpen(false)}
              closeButton
              centerModal
              title="Upload Scanned Document"
              panelStyle={{ width: 600 }}
            >
              <div>
                <Spacer size={5} />
                <FileInput
                  onChange={handleScannedDocInputChange}
                  fileName={scannedFile?.name}
                  errors={fileInputError}
                />
                <Spacer size={20} />
                <TextInput
                  required
                  label="File Description"
                  value={scannedFileDesc}
                  horizontalLabel={false}
                  onChange={handleFileDescriptionChange}
                  errors={fileDescriptionError}
                />
                <Spacer size={20} />
                <div style={{ width: 100, marginLeft: 'auto' }}>
                  <Button
                    label="Submit"
                    loading={uploadingScannedFile}
                    onClick={uploadScannedFile}
                  />
                </div>
              </div>
            </Modal>
          )}
        </Grid2>
      </Grid2>
    </AccountsMainPanel>
  );
};
